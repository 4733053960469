import { PropertyCategoryIdentifierEnum } from 'common/data/property-category/identifier.enum';
import { PropertyCategoryIdentifierAdapter } from 'common/data/property-category/identifier/adapter';
import { configGetEnv } from 'common/helper/config/get-env';
import { objectCompare } from 'common/helper/object/compare';
import { BrowserCookieInterface } from 'common/module/browser/cookie.interface';
import { DataStoreEvent } from 'common/module/data/store.event';
import { FilterParametersBaseInterface } from 'common/module/filter/parameters-base.interface';
import { FilterParamsEnum } from 'common/module/filter/params.enum';
import { FilterStore } from 'common/module/filter/store';
import { StatsTealiumDataLayerEventCategoryEnum } from 'common/module/stats/tealium/data-layer/event-category.enum';
import { StatsTealiumProviderInterface } from 'common/module/stats/tealium/provider.interface';

export class RecentSearchesStore {
  /**
   * Value to understand if the call is for the load apply or for subsequent applies
   */
  private loadFiltersApplyFinished: boolean = false;

  /**
   * Name of the cookie
   */
  private cookieName: string = 'recent_searches';

  /**
   * Constructor
   */
  constructor(
    private browserCookieService: BrowserCookieInterface,
    private appliedFiltersStore: FilterStore,
    private propertyCategoryIdentifierAdapter: PropertyCategoryIdentifierAdapter,
    private statsTealium: StatsTealiumProviderInterface
  ) {}

  /**
   * Init the store
   */
  public init(): void {
    this.observers();
  }

  /**
   * @description sends interaction event to tealium
   * @param {string} categoryId - category of the search
   */
  public onSearchClick = (isNewUser: boolean, categoryId: string): void => {
    const category = this.propertyCategoryIdentifierAdapter.getData(
      parseInt(categoryId, 10)
    ) as PropertyCategoryIdentifierEnum;

    const tealiumCategory = isNewUser
      ? StatsTealiumDataLayerEventCategoryEnum.recentSearchesVariantB
      : StatsTealiumDataLayerEventCategoryEnum.recentSearchesVariantC;

    this.statsTealium.send({
      sendToGa: true,
      event_action: 'search',
      event_category: tealiumCategory,
      event_label: category,
      tealium_event: tealiumCategory,
    });
  };

  /**
   * @description get recent searches from cookie
   */
  private getRecentSearches(): Array<Record<string, unknown>> {
    const cookie = this.browserCookieService.getData(this.cookieName) as string;
    return cookie
      ? typeof cookie === 'string'
        ? JSON.parse(cookie)
        : (cookie as unknown as Array<Record<string, unknown>>)
      : [];
  }

  /**
   * @description get lists of values to save according to category
   * @param category Category at the moment of execution
   * @returns Fields to get the information to save
   */
  private getRequiredFiles(categoryId: string): FilterParamsEnum[] {
    const category = this.propertyCategoryIdentifierAdapter.getData(
      parseInt(categoryId, 10)
    ) as PropertyCategoryIdentifierEnum;

    switch (category) {
      case PropertyCategoryIdentifierEnum.residentialForRent:
        return [
          FilterParamsEnum.propertyTypeId,
          FilterParamsEnum.minPrice,
          FilterParamsEnum.maxPrice,
          FilterParamsEnum.bedrooms,
          FilterParamsEnum.furnishing,
        ];

      case PropertyCategoryIdentifierEnum.residentialForSale:
        return [
          FilterParamsEnum.propertyTypeId,
          FilterParamsEnum.minPrice,
          FilterParamsEnum.maxPrice,
          FilterParamsEnum.bedrooms,
          FilterParamsEnum.completionStatus,
        ];

      case PropertyCategoryIdentifierEnum.commercialForRent:
        return [
          FilterParamsEnum.propertyTypeId,
          FilterParamsEnum.minArea,
          FilterParamsEnum.maxArea,
          FilterParamsEnum.minPrice,
          FilterParamsEnum.maxPrice,
        ];

      case PropertyCategoryIdentifierEnum.commercialForSale:
        return [
          FilterParamsEnum.propertyTypeId,
          FilterParamsEnum.minArea,
          FilterParamsEnum.maxArea,
          FilterParamsEnum.minPrice,
          FilterParamsEnum.maxPrice,
        ];
    }
  }

  /**
   * Attach the observers to the filter store
   */
  private observers(): void {
    this.appliedFiltersStore.getEventEmitter().addListener(DataStoreEvent.updateState, this.updateCookieOnFilterUpdate);
  }

  /**
   *
   * @param item - new search
   * @returns whether or not the search is new or is coming from an existing past search
   */
  private isComingFromRecentSearch(item: Record<string, unknown>): boolean {
    const pastSearches = this.getRecentSearches();

    return !!pastSearches.find((search) => {
      // Check if same number of keys
      const searchKeys = Object.keys(search);
      if (searchKeys.length === Object.keys(item).length) {
        return objectCompare(search, item);
      }
      // It's not the same
      return false;
    });
  }

  /**
   * Updates the cookie according to the new searches
   */
  private updateCookieOnFilterUpdate = (): void => {
    if (configGetEnv().currentRoute === 'homepage_primary' && !this.loadFiltersApplyFinished) {
      this.loadFiltersApplyFinished = true;
      return;
    }

    const filterParams = this.appliedFiltersStore.getState() as FilterParametersBaseInterface;

    const validFilters = Object.keys(filterParams).reduce((result, current: keyof FilterParametersBaseInterface) => {
      if (current.indexOf('filter[') > -1) {
        if (current === FilterParamsEnum.locationsIds) {
          result[current] = filterParams[current].value;
        } else if (Array.isArray(filterParams[current].value)) {
          if ((filterParams[current].value as string[]).length) {
            result[current] = filterParams[current].value;
          }
        } else if (current === FilterParamsEnum.furnishing) {
          if (filterParams[current].value !== '0') {
            result[current] = filterParams[current].value;
          }
        } else if (filterParams[current].value) {
          result[current] = filterParams[current].value;
        }
      }
      return result;
    }, {} as Record<FilterParamsEnum, unknown>);

    const requiredFields = this.getRequiredFiles(filterParams[FilterParamsEnum.categoryId].value);

    const { hasAnyOfTheRequiredFields, item } = requiredFields.reduce(
      (result, current: keyof FilterParametersBaseInterface) => {
        if (validFilters[current]) {
          result.hasAnyOfTheRequiredFields = true;
        }
        return result;
      },
      {
        hasAnyOfTheRequiredFields: false,
        item: {
          ...validFilters,
        } as Record<string, unknown>,
      }
    );

    if (
      !this.isComingFromRecentSearch(validFilters) &&
      (hasAnyOfTheRequiredFields || (item[FilterParamsEnum.locationsIds] as string[]).length)
    ) {
      const pastSearches = this.getRecentSearches().slice(0, 2);
      this.browserCookieService.setData(this.cookieName, JSON.stringify([item, ...pastSearches]), undefined, '/');
    }
  };
}
