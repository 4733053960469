import { FunctionalComponent, h } from 'preact';

import { IconTemplatePropsInterface } from 'common/module/icon/template-props.interface';

export const IconBackTemplate: FunctionalComponent<IconTemplatePropsInterface> = (props) => (
  <svg className={props.class} viewBox='0 0 17 15' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <g id='thick-arrow-left' stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
      <g id='Header' transform='translate(-12.000000, -18.000000)' fill='#2D383F'>
        {/* tslint:disable-next-line:max-line-length */}
        <polyline
          id='Arrow-Back'
          points='28.8941119 26.4273205 28.8941119 24.5726795 15.5822503 24.5726795 20.8814255 19.3114292 19.5605431 18 13.327363 24.1885708 12 25.4935659 13.3208824 26.8178634 19.5605431 33 20.8814255 31.6885708 15.5822503 26.4273205'
        />
      </g>
    </g>
  </svg>
);
