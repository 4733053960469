import { FunctionComponent, h } from 'preact';

import { LazyLoadingComponent } from 'common/module/lazy-loading/component';
import { PageBottomNavigationEnum } from 'mobile/module/bottom-navigation/page-bottom-navigation.enum';

import { BottomNavigationComponent } from './component';

export const BottomNavigationAsyncComponent: FunctionComponent<{ page: PageBottomNavigationEnum }> = ({ page }) => (
  <LazyLoadingComponent visible>
    <BottomNavigationComponent page={page} />
  </LazyLoadingComponent>
);
