import { EventEmitterInterface } from 'pf-frontend-common/src/module/event/emitter.interface';

import { PropertyCategoryIdentifierEnum } from 'common/data/property-category/identifier.enum';
import { PropertyCategoryIdentifierAdapter } from 'common/data/property-category/identifier/adapter';
import { FilterHandlerViewStore } from 'common/module/filter/handler/view-store';
import { FilterParametersInterface } from 'common/module/filter/parameters.interface';
import { FilterParamsEnum } from 'common/module/filter/params.enum';
import { FilterSearchUrlAdapter } from 'common/module/filter/search/url/adapter';
import { FilterStore } from 'common/module/filter/store';

import { HomePageSearchBoxOptionsInterface } from './options.interface';
import { HomePageSearchBoxTemplatePropsInterface } from './template-props.interface';
import { HomePageSearchBoxTracker } from './tracker';

export class HomePageSearchBoxViewStore extends FilterHandlerViewStore<
  HomePageSearchBoxTemplatePropsInterface,
  HomePageSearchBoxOptionsInterface
> {
  /**
   * Autocomplete search uri
   */
  private searchUri: string;

  constructor(
    eventEmitter: EventEmitterInterface,
    filterStore: FilterStore,
    private filterSearchUrlAdapter: FilterSearchUrlAdapter,
    private propertyCategoryIdentifierAdapter: PropertyCategoryIdentifierAdapter,
    private tracker: HomePageSearchBoxTracker
  ) {
    super(eventEmitter, filterStore);
  }

  /**
   * @inheritdohc
   */
  public initialize(props: HomePageSearchBoxOptionsInterface): void {
    super.initialize(props);
    this.state = {
      ...this.state,
      errors: {},
      propertyCategoryIdentifierAdapter: this.propertyCategoryIdentifierAdapter,
      showLocationError: false,
      onChangeFilters: this.onChangeFilters,
      onSubmit: this.onSubmit,
      onToggleCommercialProperties: this.tracker.onToggleCommercialClick,
      onBuyVsRentClick: this.tracker.onBuyVsRentLinkClick,
    };

    // Store search uri
    this.searchUri = props.searchUri;
  }

  /**
   * @inheritdoc
   */
  protected onChangeFilters = (filters: FilterParametersInterface) => {
    this.changeFilters(filters);
  };

  /**
   * Form submitted
   */
  private onSubmit = (e: Event) => {
    e.preventDefault();

    this.submit();
  };

  /**
   * Form submitted
   */
  private submit(): void {
    // Send data to tealium
    const { filterParams } = this.getState();
    const category = this.propertyCategoryIdentifierAdapter.getData(
      parseInt(filterParams[FilterParamsEnum.categoryId]?.value, 10)
    );

    const isCommercial = this.propertyCategoryIdentifierAdapter.isCommercial(
      category as PropertyCategoryIdentifierEnum
    );

    const filterValues = [
      {
        name: 'property_type',
        value: filterParams[FilterParamsEnum.propertyTypeId]?.value,
      },
      {
        name: 'max_price',
        value: filterParams[FilterParamsEnum.maxPrice]?.value,
      },
      ...(isCommercial
        ? [
            {
              name: 'area',
              value: filterParams[FilterParamsEnum.maxArea]?.value,
            },
          ]
        : [
            {
              name: 'bedrooms',
              value: filterParams[FilterParamsEnum.bedrooms]?.value,
            },
          ]),
    ];

    this.tracker.onSearchButtonClick(
      `search_box<${filterParams[FilterParamsEnum.locationsIds].value.join(
        '|'
      )}>;commercial_properties<${isCommercial}>;${filterValues.reduce((result, { name, value }) => {
        if (value) {
          result += `${name}<${value}>;`;
        }
        return result;
      }, '')}`
    );

    this.redirect();
  }

  /**
   * Redirect user
   */
  private redirect(): void {
    this.filterSearchUrlAdapter.redirect(this.searchUri, this.getState().filterParams);
  }
}
