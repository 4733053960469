import { Component, createRef, h, RefObject } from 'preact';

import { IconCloseTemplate } from '../icon/close-template';
import { Input2Component } from '../input2/component';
import { InputSearchComponentPropsInterface } from './component-props.interface';

export class InputSearchComponent extends Component<InputSearchComponentPropsInterface> {
  /**
   * Input element reference
   */
  private ref: RefObject<HTMLInputElement> = createRef<HTMLInputElement>();

  /**
   * @inheritDoc
   */
  public componentDidMount(): void {
    if (this.ref.current && this.props.isAutoFocused) {
      this.ref.current.focus();
    }
  }

  /**
   * @inheritDoc
   */
  public render(): preact.JSX.Element {
    const { label, icon, onInput, isMultiple, selectedValues, renderTag, inputAutoComplete, ...props } = this.props;
    const isInputActive = isMultiple || !(selectedValues && selectedValues.length);

    return (
      <Input2Component
        {...props}
        prefix={
          <div
            className='input-search__prefix'
            onClick={(e) => {
              if (props.onClickIcon) {
                e.stopPropagation();
                props.onClickIcon(e);
              }
            }}
          >
            {icon || (
              <svg viewBox='0 0 16 16' className='input-search__icon'>
                <use xlinkHref='#svg-search' />
              </svg>
            )}
            <div className='input-search__tags'>
              {selectedValues && selectedValues.map((value, index) => renderTag && renderTag(value, index))}
            </div>
          </div>
        }
        suffixCssClass='input-search__suffix'
        suffix={
          props.onClickClear &&
          props.value && (
            <div
              className='input-search__clear'
              onClick={(e) => {
                e.stopPropagation();
                props.onClickClear(e);
              }}
            >
              {<IconCloseTemplate class='input-search__icon' />}
            </div>
          )
        }
      >
        {isInputActive && (
          <input
            value={props.value}
            ref={this.ref}
            className='input2__input input2__input--style2 input-search__input'
            placeholder={label}
            onInput={onInput}
            autoComplete={inputAutoComplete}
          />
        )}
      </Input2Component>
    );
  }
}
