import { EventEmitterInterface } from 'pf-frontend-common/dist/module/event/emitter.interface';
import { WindowServiceInterface } from 'pf-frontend-common/src/service/window/service.interface';

import { AutocompleteResultInterface } from 'common/module/autocomplete/result.interface';
import { AutocompleteViewStore as CommonAutocompleteViewStore } from 'common/module/autocomplete/view-store';
import { AutocompleteViewStoreEvent as BaseAutocompleteEvent } from 'common/module/autocomplete/view-store.event';
import { KeyboardCharcodeEnum } from 'common/module/keyboard/charcode.enum';
import { AutocompleteTemplatePropsInterface } from 'mobile/module/autocomplete/template-props.interface';
import { AutocompleteViewStoreEvent } from 'mobile/module/autocomplete/view-store.event';
import { AutocompleteViewStoreOptionsInterface } from 'mobile/module/autocomplete/view-store-options.interface';

export class AutocompleteViewStore extends CommonAutocompleteViewStore {
  /**
   * @inheritDoc
   */
  protected state: AutocompleteTemplatePropsInterface;

  /**
   * Constructor
   */
  public constructor(eventEmitter: EventEmitterInterface, protected windowService: WindowServiceInterface) {
    super(eventEmitter, windowService);
  }

  /**
   * @inheritDoc
   */
  public initialize(options: AutocompleteViewStoreOptionsInterface): void {
    this.state = {
      ...this.state,
      appendElement: options.appendElement || null,
      isActive: false,
      limit: options.limit,
      selected: options.selected || [],
      onClickTag: this.onClickTag,
      onClickAutocomplete: this.onClickAutocomplete,
      onFocusInput: this.onFocusInput,
      onBlurInput: this.onBlurInput,
      onKeyDownInput: this.onKeyDownInput,
      onKeyUpInput: this.onKeyUpInput,
      placeholder: options.placeholder,
      autocompleteResults: [],
    };
  }

  /**
   * @inheritdoc
   */
  public getState(): AutocompleteTemplatePropsInterface {
    return this.state;
  }

  /**
   * Set selected results
   */
  public setSelected(selected: AutocompleteResultInterface[]): void {
    this.state.selected = selected;

    this.setState(this.state);
  }

  /**
   * Get selected results
   */
  public getSelected(): AutocompleteResultInterface[] {
    return this.state.selected;
  }

  /**
   * Add result to selected results
   */
  public addSelected(result: AutocompleteResultInterface): void {
    const foundSelection = this.state.selected.filter((selection) => result.id === selection.id);

    if (foundSelection && foundSelection[0]) {
      return;
    }

    this.state.selected.push(result);

    this.setSelected(this.state.selected);
  }

  /**
   * Remove result from selected results
   */
  public removeSelected(result: AutocompleteResultInterface): void {
    this.state.selected = this.state.selected.filter((selection) => result.id !== selection.id);

    this.setSelected(this.state.selected);
  }

  /**
   * @inheritDoc
   */
  protected onSelectResultItem(e: Event, result: AutocompleteResultInterface): void {
    if (!result) {
      return;
    }

    this.addSelected(result);
    this.setSearchString('');
    this.setInputValue('');

    super.onSelectResultItem(e, result);

    // stop propagation so onClickAutocomplete handle wont be triggered
    e.stopPropagation();
  }

  /**
   * Handles autocomplete select result items event
   */
  protected onSelectResultItems(e: Event, result: AutocompleteResultInterface[]): void {
    this.setSelected(result);
    this.setSearchString('');
    this.setInputValue('');

    // stop propagation so onClickAutocomplete handle wont be triggered
    e.stopPropagation();
  }

  /**
   * Click on tag
   */
  protected onClickTag = (e: Event, result: AutocompleteResultInterface): void => {
    e.stopPropagation();

    this.removeSelected(result);
    this.getEventEmitter().emit(AutocompleteViewStoreEvent.clickTag, e, result);
  };

  /**
   * @inheritDoc
   */
  protected onClickAutocomplete = (e: Event): void => {
    if (!this.inputEl) {
      return;
    }

    // Show dropdown when there are autocomplete results
    this.toggle(!!this.state.autocompleteResults.length);

    this.inputEl.focus();
  };

  /**
   * @inheritDoc
   */
  protected onFocusInput = (e: Event) => {
    super.onFocusInput(e);
    this.state.isActive = true;

    this.setState(this.state);
  };

  /**
   * @inheritDoc
   */
  protected onBlurInput = (e: Event) => {
    this.state.isActive = false;

    this.setState({ ...this.state, searchString: '' });
    this.eventEmitter.emit(BaseAutocompleteEvent.blurInput, e);
  };

  /**
   * Select the first search item from the autocomplete list
   * @param {KeyboardEvent} e
   */
  protected selectFirstResultItem(e: KeyboardEvent): void {
    const { isOpened, autocompleteResults } = this.state;

    // Ignore searching if autocomplete is not open or if there are no search suggestions
    if (!isOpened || !autocompleteResults.length) {
      return;
    }

    // Extract the first option from the search suggestions
    const results = autocompleteResults[autocompleteResults.length - 1];
    const suggestion = results.suggestions[0];

    // And finally, trigger the search
    this.onSelectResultItem(e, suggestion);
  }

  /**
   * Handles autocomplete keydown event
   *
   * @param e - Event
   */
  protected onKeyDownInput = (e: KeyboardEvent) => {
    // This was done in order to make it work in all browsers
    // and to reuse the onKeyUpInput logic from the parent component
    if (e.keyCode === KeyboardCharcodeEnum.enter) {
      e.preventDefault();
    }
  };

  /**
   * Handles autocomplete keyup event
   *
   * @param e - Event
   */
  protected onKeyUpInput = (e: KeyboardEvent) => {
    super.onKeyUpInput(e);

    if (e.keyCode === KeyboardCharcodeEnum.enter) {
      this.selectFirstResultItem(e);
    }
  };
}
