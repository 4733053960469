import { EventEmitterInterface } from 'pf-frontend-common/dist/module/event/emitter.interface';

import { configGetCountry } from 'common/helper/config/get-country';
import { ConfigLanguageCodeEnum } from 'common/helper/config/language-code.enum';
import { i18nTranslate } from 'common/helper/i18n/translate';
import { AutocompleteSectionInterface } from 'common/module/autocomplete/section.interface';
import { AutocompleteViewStoreEvent } from 'common/module/autocomplete/view-store.event';
import { DataStore } from 'common/module/data/store';
import { FilterParamsEnum } from 'common/module/filter/params.enum';
import { FilterStore } from 'common/module/filter/store';
import { PropertyAutocompleteStoreDecoratorEvent } from 'common/module/property/autocomplete/store/decorator.event';
import { PropertyAutocompleteStoreDecoratorInterface } from 'common/module/property/autocomplete/store/decorator.interface';
import { PropertySerpLightPopularSearches } from 'common/module/property/serp/light/popular-searches';
import { StatsTealiumDataLayerEventCategoryEnum } from 'common/module/stats/tealium/data-layer/event-category.enum';
import { StatsTealiumProviderInterface } from 'common/module/stats/tealium/provider.interface';
import { LocationAutocompleteHistoryServiceInterface } from 'common/service/location-autocomplete-history/service.interface';
import { AutocompleteViewStore } from 'mobile/module/autocomplete/view-store';
import { AutocompleteViewStoreEvent as MobileAutocompleteViewStoreEvent } from 'mobile/module/autocomplete/view-store.event';

import { LocationAutocompleteOptionsInterface } from './options.interface';

export class LocationAutocompleteViewStore extends DataStore {
  protected timer: NodeJS.Timeout;

  private onChange: (locationIds: string[]) => void;

  constructor(
    eventEmitter: EventEmitterInterface,
    private autocompleteStore: PropertyAutocompleteStoreDecoratorInterface,
    private autocompleteViewStoreService: AutocompleteViewStore,
    private locationAutocompleteHistoryService: LocationAutocompleteHistoryServiceInterface,
    private filterStoreService: FilterStore,
    private statsTealium: StatsTealiumProviderInterface,
    private languageCode: ConfigLanguageCodeEnum
  ) {
    super(eventEmitter);
  }

  /**
   * @inheritdoc
   */
  public initialize({ onChange, placeholderText }: LocationAutocompleteOptionsInterface): void {
    this.onChange = onChange;

    this.initAutocomplete(placeholderText);
  }

  /**
   * Init autocomplete
   */
  private initAutocomplete(placeholder: string): void {
    this.autocompleteViewStoreService.initialize({
      placeholder,
      initialValue: this.filterStoreService.getState()[FilterParamsEnum.query].value,
    });

    this.autocompleteViewStoreService
      .getEventEmitter()
      .addListener(AutocompleteViewStoreEvent.clickInput, this.onClickAutocomplete);

    this.autocompleteViewStoreService
      .getEventEmitter()
      .addListener(AutocompleteViewStoreEvent.keyUp, this.onKeyUpAutocomplete);

    this.autocompleteViewStoreService
      .getEventEmitter()
      .addListener(AutocompleteViewStoreEvent.selectResultItem, this.onSelectResultItemAutocomplete);

    this.autocompleteViewStoreService
      .getEventEmitter()
      .addListener(MobileAutocompleteViewStoreEvent.clickTag, this.onClickTagAutocompleteStore);

    this.autocompleteStore
      .getEventEmitter()
      .addListener(PropertyAutocompleteStoreDecoratorEvent.searchSuccess, this.onSearchSuccessAutocompleteStore);
  }

  /**
   * Autocomplete click event handler
   */
  private onClickAutocomplete = () => {
    this.setAutocompleteHistory();
  };

  /**
   * Set autocomplete history
   */
  private setAutocompleteHistory(): void {
    const storage = this.locationAutocompleteHistoryService.getLocations();

    if (storage?.length) {
      this.autocompleteViewStoreService.setAutocompleteResults([
        {
          name: i18nTranslate('History'),
          id: 'history',
          suggestions: storage.map((location) => ({
            id: location.id,
            text: `${location.abbreviation ? location.abbreviation + ' - ' : ''}${location.name}`,
            description: location.path_name,
            sectionId: 'history',
          })),
        },
      ]);
    } else {
      this.setPopularSearch();
    }
  }

  /**
   * Set autocomplete popular searches
   */
  private setPopularSearch(): void {
    const popularSearches = PropertySerpLightPopularSearches[configGetCountry().code]?.[this.languageCode] || [];
    this.autocompleteViewStoreService.setAutocompleteResults([
      {
        name: i18nTranslate('Popular searches'),
        id: 'popular-searches',
        suggestions: popularSearches.map((location) => ({
          id: location.id,
          text: location.text,
          description: location.description,
          sectionId: 'popular-searches',
        })),
      },
    ]);
  }

  /**
   * Autocomplete keyup event handler
   */
  private onKeyUpAutocomplete = () => {
    const autocompleteState = this.autocompleteViewStoreService.getState();

    this.autocompleteStore.search(autocompleteState.searchString);
  };

  /**
   * Autocomplete select result item event handler
   */
  private onSelectResultItemAutocomplete = () => {
    this.onChange(this.autocompleteViewStoreService.getState().selected.map((item) => item.id));
    this.setAutocompleteHistory();
  };

  /**
   * Click autocomplete tag
   */
  private onClickTagAutocompleteStore = () => {
    this.onChange(this.autocompleteViewStoreService.getState().selected.map((item) => item.id));
  };

  /**
   * Triggers when autocomplete search succeded
   */
  private onSearchSuccessAutocompleteStore = (): void => {
    const results = this.autocompleteStore.getAutocompleteResults();

    if (results?.length) {
      this.autocompleteViewStoreService.setAutocompleteResults(results);
    } else {
      // Update autocomplete with locations from history
      this.setAutocompleteHistory();
    }

    const autocompleteState = this.autocompleteViewStoreService.getState();

    this.onSearchBarTyped(autocompleteState.searchString, autocompleteState.autocompleteResults);
    this.autocompleteViewStoreService.toggle(!!autocompleteState.autocompleteResults.length);
  };

  private onSearchBarTyped(value: string, results: AutocompleteSectionInterface[]): void {
    const hasSuggestions = results.some((result) => result.id === '1' && !!result.suggestions.length);

    clearTimeout(this.timer);

    this.timer = setTimeout(() => {
      if (value && !hasSuggestions) {
        this.statsTealium.send({
          sendToGa: true,
          tealium_event: 'search_bar_typed',
          event_category: StatsTealiumDataLayerEventCategoryEnum.search,
          event_action: 'search_bar_typed',
          event_label: value,
          search_keywords: value,
        });
      }
    }, 500);
  }
}
