import { AutocompleteTemplatePropsInterface } from 'common/module/autocomplete/template-props.interface';
import { preactConnectStore } from 'common/module/preact/connect-store';
import { PropertySerpLightViewStore } from 'common/module/property/serp/light/view-store';
import { PropertySerpLightViewStoreStateInterface } from 'common/module/property/serp/light/view-store-state.interface';
import { AutocompleteViewStore } from 'mobile/module/autocomplete/view-store';
import { PropertySerpLightWizardTemplate } from 'mobile/module/property/serp/light/wizard/template';
import { PropertySerpLightAutocompleteService } from 'mobile/service/property-serp-light-autocomplete/service';
import { PropertySerpLightViewStoreService } from 'mobile/service/property-serp-light-view-store/service';

const LightWizardComponent = preactConnectStore<AutocompleteViewStore, AutocompleteTemplatePropsInterface>(
  PropertySerpLightAutocompleteService,
  (store) => store.getState()
)(PropertySerpLightWizardTemplate);

export const PropertySerpLightWizardComponent = preactConnectStore<
  PropertySerpLightViewStore,
  PropertySerpLightViewStoreStateInterface
>(PropertySerpLightViewStoreService, (store) => store.getState())(LightWizardComponent);
