import { useEffect } from 'preact/hooks';
import { useInView } from 'react-intersection-observer';

import { StatsTealiumDataLayerConversionEventInterface } from 'common/module/stats/tealium/data-layer/conversion-event.interface';
import { StatsTealiumDataLayerEventActionEnum } from 'common/module/stats/tealium/data-layer/event-action.enum';
import { StatsTealiumDataLayerEventCategoryEnum } from 'common/module/stats/tealium/data-layer/event-category.enum';
import { StatsTealiumProviderService } from 'common/service/stats-tealium-provider/service';

export const useAppCardAppBannerImpression = (
  devicePlatform: string,
  test115Type: string
): ((node?: Element | null | undefined) => void) => {
  const tracking = StatsTealiumProviderService();

  const { ref, inView } = useInView({
    root: null,
    rootMargin: '0px 0px 0px 0px',
    threshold: 0.5,
  });

  useEffect(() => {
    if (inView) {
      tracking.send({
        tealium_event: 'flagship',
        event_category: StatsTealiumDataLayerEventCategoryEnum.appBanner,
        event_value: '1',
        event_action:
          devicePlatform === 'ios'
            ? StatsTealiumDataLayerEventActionEnum.appBannerIosImpression
            : StatsTealiumDataLayerEventActionEnum.appBannerAndroidImpression,
        event_label: test115Type,
      } as StatsTealiumDataLayerConversionEventInterface);
    }
  }, [inView]);

  return ref;
};
