import {
  IconBinocular,
  IconBinocularFilled,
  IconHeart,
  IconHeartFilled,
  IconHouse,
  IconHouseFilled,
  IconMagnifierFilled,
  IconMagnifierThick,
  IconUser,
  IconUserFilled,
} from '@propertyfinder/icons';
import { BrowserStorageSessionServiceInterface } from 'pf-frontend-common/src/service/browser-storage-session/service.interface';

import { PageBottomNavigationEnum } from 'mobile/module/bottom-navigation/page-bottom-navigation.enum';

import { BottomNavigationNavInterface } from './nav.interface';

export const bottomNavigationGetNavs = (
  t: (key: string, count?: number) => string,
  locale: string,
  windowStorage: BrowserStorageSessionServiceInterface
): BottomNavigationNavInterface[] => [
  {
    key: PageBottomNavigationEnum.HOME,
    label: t('BottomNavigation_Home'),
    url: (windowStorage.getData(PageBottomNavigationEnum.HOME) as string) || '/',
    icon: IconHouse,
    selectedIcon: IconHouseFilled,
  },
  {
    key: PageBottomNavigationEnum.SEARCH,
    label: t('BottomNavigation_Search'),
    url: (windowStorage.getData(PageBottomNavigationEnum.SEARCH) as string) || `/${locale}/search`,
    icon: IconMagnifierThick,
    selectedIcon: IconMagnifierFilled,
  },
  {
    key: PageBottomNavigationEnum.EXPLORE,
    label: t('BottomNavigation_Explore'),
    url:
      (windowStorage.getData(PageBottomNavigationEnum.EXPLORE) as string) || `/${locale}/area-insights/explore/dubai`,
    icon: IconBinocular,
    selectedIcon: IconBinocularFilled,
  },
  {
    key: PageBottomNavigationEnum.SAVED,
    label: t('BottomNavigation_Saved'),
    url: (windowStorage.getData(PageBottomNavigationEnum.SAVED) as string) || `/${locale}/user/saved-properties`,
    icon: IconHeart,
    selectedIcon: IconHeartFilled,
    secured: true,
  },
  {
    key: PageBottomNavigationEnum.USER,
    label: t('BottomNavigation_User'),
    url: (windowStorage.getData(PageBottomNavigationEnum.USER) as string) || `/${locale}/user`,
    icon: IconUser,
    selectedIcon: IconUserFilled,
    secured: true,
  },
];
