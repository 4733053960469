import { urlSerializeParams } from 'pf-frontend-common/src/helper/url/serialize-params';
import { h } from 'preact';
import { useState } from 'preact/hooks';

import { FilterParametersFieldOptionsInterface } from 'common/module/filter/parameters/field-options.interface';
import { FilterParamsEnum } from 'common/module/filter/params.enum';

import { RecentSearchesComponentFactoryPropsInterface } from './component-factory-props.interface';
import { RecentSearchesComponentPropsInterface } from './component-props.interface';
import { RecentSearchesTemplate } from './template';

export const RecentSearchesComponentFactory =
  ({ filterSearchUrlAdapter, recentSearchesStore, searchUri }: RecentSearchesComponentFactoryPropsInterface) =>
  ({ isNewUser, searches }: RecentSearchesComponentPropsInterface) => {
    // parse recent searches
    const parsedSearches = JSON.parse(searches) as Array<{
      title: string;
      description: string;
      data: Record<FilterParamsEnum, unknown>;
    }>;

    const mappedSearches = parsedSearches.map(({ title, description, data }) => {
      const paramKeys = Object.keys(data).reduce((result, current: FilterParamsEnum) => {
        result[current] = { value: data[current], choices: [] };
        return result;
      }, {} as Record<string, FilterParametersFieldOptionsInterface<unknown, unknown>>);
      const serializedParams = filterSearchUrlAdapter.serialize(paramKeys);
      const searchParamsUrl = urlSerializeParams(serializedParams);

      return {
        categoryId: data[FilterParamsEnum.categoryId] as string,
        title,
        description,
        link: `${searchUri}?${searchParamsUrl}`,
      };
    });

    const [items] = useState(mappedSearches);

    return (
      <RecentSearchesTemplate
        onClick={(category: string) => recentSearchesStore.onSearchClick(!!isNewUser, category)}
        items={items}
      />
    );
  };
