import { Fragment, FunctionComponent, h } from 'preact';
import { useCallback, useEffect, useRef, useState } from 'preact/hooks';

import { bottomNavigationOnBoardingEnabled } from 'common/features/bottom-navigation-on-boarding/enabled';
import { i18nTranslate } from 'common/helper/i18n/translate';
import { useBottomNavigationOnClickOutside } from 'mobile/module/bottom-navigation/on-click-outside.hook';
import { BOTTOM_NAVIGATION_STORAGE_ONBOARDING_KEY } from 'mobile/module/bottom-navigation/storage-onboarding-key.constant';

export const BottomNavigationOnBoardingComponent: FunctionComponent = () => {
  const [isOnBoardingActive, setIsOnBoardingActive] = useState(
    bottomNavigationOnBoardingEnabled && !window.localStorage.getItem(BOTTOM_NAVIGATION_STORAGE_ONBOARDING_KEY)
  );

  const tooltipRef = useRef<HTMLDivElement>(null);

  const onBoardingClose = useCallback(() => {
    window.localStorage.setItem(BOTTOM_NAVIGATION_STORAGE_ONBOARDING_KEY, 'true');
    setIsOnBoardingActive(false);
  }, []);

  useEffect(() => {
    if (isOnBoardingActive) {
      document.body.style.overflowY = 'hidden';
      return;
    }

    document.body.style.overflowY = 'scroll';
  }, [isOnBoardingActive]);

  useBottomNavigationOnClickOutside(tooltipRef?.current, () => {
    onBoardingClose();
  });

  if (!isOnBoardingActive) {
    return null;
  }

  return (
    <Fragment>
      <div className={'bottom-navigation-on-boarding__overlay'} />
      <div ref={tooltipRef} className={'bottom-navigation-on-boarding__container'}>
        <b>{i18nTranslate('BottomNavigation_OnBoardingTitle')}</b>
        <br />
        {i18nTranslate('BottomNavigation_OnBoardingDescription')}
      </div>
    </Fragment>
  );
};
