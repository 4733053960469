import { Fragment, h } from 'preact';

import { HomePageLinksComponentPropsInterface } from './component-props.interface';
import { HomePageLinksTracker } from './tracker';

export const HomePageLinksComponentFactory =
  (tracker: HomePageLinksTracker) =>
  ({ className, subLabel: SubLabel, links }: HomePageLinksComponentPropsInterface) => {
    return (
      <Fragment>
        {links.map(({ link, title, label, subLabel, id }) => (
          <a href={link} title={title} className={className} onClick={() => tracker.onLinkClick(id)}>
            {label}
            {subLabel && SubLabel ? <SubLabel>{subLabel}</SubLabel> : null}
          </a>
        ))}
      </Fragment>
    );
  };
