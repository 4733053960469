import { StatsTealiumDataLayerEventInterface } from 'common/module/stats/tealium/data-layer/event.interface';
import { StatsTealiumDataLayerEventCategoryEnum } from 'common/module/stats/tealium/data-layer/event-category.enum';
import { StatsTealiumProviderInterface } from 'common/module/stats/tealium/provider.interface';

export class HomePageLinksTracker {
  /**
   * Constructor
   */
  constructor(private statsTealium: StatsTealiumProviderInterface) {}

  /**
   * @param label - tracking eventLabel
   */
  public onLinkClick(label: string): void {
    this.tealiumSend({
      event_label: label,
      event_category: StatsTealiumDataLayerEventCategoryEnum.homePageClickOutideSearchArea,
    });
  }

  /**
   * Send to the tealium
   */
  private tealiumSend = (data: StatsTealiumDataLayerEventInterface) => {
    this.statsTealium.send({
      sendToGa: true,
      event_action: 'click',
      tealium_event: 'secondary_link_clicks',
      ...data,
    });
  };
}
