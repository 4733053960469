import { ModalService } from 'pf-frontend-common/src/service/modal/service';
import { WindowLocationService } from 'pf-frontend-common/src/service/window-location/service';

import { EmailAlertUnsubscribeView } from 'common/module/email-alert/unsubscribe/view';
import { EmailAlertUnsubscribeViewStoreFactory } from 'common/module/email-alert/unsubscribe/view-store.factory';
import { PreactView } from 'common/module/preact/view';
import { EmailAlertUnsubscribeTemplate } from 'mobile/module/email-alert/unsubscribe/template';

export function EmailAlertUnsubscribeViewFactory(): EmailAlertUnsubscribeView {
  return new EmailAlertUnsubscribeView(
    WindowLocationService(),
    ModalService(),
    new PreactView(EmailAlertUnsubscribeTemplate, EmailAlertUnsubscribeViewStoreFactory())
  );
}
