import { Fragment, FunctionalComponent, h } from 'preact';

import { domClassMerge } from '../../common/helper/dom/class-merge';
import { LoaderTemplate } from '../loader/template';
import { ButtonButtonTemplateInterface } from './button-template-props.interface';
import { ButtonIconPositionEnum } from './icon-position.enum';
import { ButtonLinkTemplatePropsInterface } from './link-template-props.interface';
/**
 * Web UI Kit Button tempalte
 * Figma: https://www.figma.com/file/gRARY1Vi4W2Ow1vRLw02am/PF_Consumer-Web-Kit?node-id=3869%3A2340
 */
export const LibraryButtonTemplate: FunctionalComponent<
  ButtonLinkTemplatePropsInterface | ButtonButtonTemplateInterface
> = ({ icon = {}, componentType, ...props }) => {
  const { component: Icon, position: iconPosition, iconFromLibrary = false } = icon;
  const typeClass = `library-button--${componentType}`;
  // TODO-FE[TPNX-2699] Use loader as per design
  const renderLoader = () => <LoaderTemplate cssClass={`library-button__loader ${typeClass}__loader`} />;
  const renderIcon = () =>
    Icon &&
    (iconFromLibrary ? (
      <Icon
        className={domClassMerge(
          `library-button__icon library-button__icon--${iconPosition} ${typeClass}__icon`,
          props.iconCssClass
        )}
      />
    ) : (
      <Icon
        clipped={false}
        class={domClassMerge(
          `library-button__icon library-button__icon--${iconPosition} ${typeClass}__icon`,
          props.iconCssClass
        )}
      />
    ));
  const renderContent = () => (
    <Fragment>
      {(iconPosition === ButtonIconPositionEnum.left || iconPosition === ButtonIconPositionEnum.top) && renderIcon()}
      {props.children}
      {iconPosition === ButtonIconPositionEnum.right && renderIcon()}
    </Fragment>
  );
  const Component = (props as ButtonLinkTemplatePropsInterface).href ? 'a' : 'button';
  return (
    <Component
      class={domClassMerge(
        'library-button',
        typeClass,
        `library-button--icon-${iconPosition}`,
        `library-button--${props.size}`,
        {
          // TODO-FE[TPNX-2699] Use loader as per design
          [`${typeClass}--loading`]: props.loading,
          // TODO-FE[TPNX-2701] Implement the disabled state for the button component
          [`library-button--disabled, ${typeClass}--disabled`]: props.disabled,
        },
        props.cssClass
      )}
      disabled={props.disabled}
      onClick={!props.loading && props.onClick}
      href={(props as ButtonLinkTemplatePropsInterface).href}
      target={(props as ButtonLinkTemplatePropsInterface).target}
      {...(props.dataId ? { 'data-id': props.dataId } : {})}
    >
      {props.loading ? renderLoader() : renderContent()}
    </Component>
  );
};
