import { CommunityGuideCardTemplatePropsInterface } from 'common/module/community-guide/card/template-props.interface';
// tslint:disable:max-line-length
// TODO-FE[https://pfinder.atlassian.net/browse/TPNX-992] - remove this file and move interface to something more common
export const locationSet1: Record<string, CommunityGuideCardTemplatePropsInterface[]> = {
  en: [
    {
      id: 50,
      image: '/images/pf_community_guides/moment/ab0052e0efcb94559c9be23133126b89f579e87f/gallery_small',
      name: 'Dubai Marina',
      tags: ['Skyscrapers', 'Marina', 'Yachts', 'Restaurants'],
      url: 'https://www.propertyfinder.ae/en/community-guides/dubai/dubai-marina',
    },
    {
      id: 41,
      image: '/images/pf_community_guides/moment/861b26723e42daa0380f43eaef2800fb7d375a18/gallery_small',
      name: 'Downtown Dubai',
      tags: ['Architecture', 'Attractions', 'Shopping', 'Restaurants'],
      url: 'https://www.propertyfinder.ae/en/community-guides/dubai/downtown-dubai',
    },
    {
      id: 36,
      image: '/images/pf_community_guides/moment/1c80a433c1c0b3a2ea56bba598cdb4751e64f748/gallery_small',
      name: 'Business Bay',
      tags: ['Towers', 'Central location', 'Restaurants'],
      url: 'https://www.propertyfinder.ae/en/community-guides/dubai/business-bay',
    },
    {
      id: 86,
      image: '/images/pf_community_guides/moment/168ee7e927aada0481e476c608830e6fe33e0c05/gallery_small',
      name: 'Palm Jumeirah',
      tags: ['Hotels', 'Beaches', 'Water activities', 'Beach clubs'],
      url: 'https://www.propertyfinder.ae/en/community-guides/dubai/palm-jumeirah',
    },
    {
      id: 73,
      image: '/images/pf_community_guides/moment/2b9f6c509f278e0837307670dad05fa90b74a37a/gallery_small',
      name: 'Jumeirah Village Circle',
      tags: ['Quiet', 'Parks', 'Restaurants'],
      url: 'https://www.propertyfinder.ae/en/community-guides/dubai/jumeirah-village-circle',
    },
    {
      id: 71,
      image: '/images/pf_community_guides/moment/f303311ba66c05463a5846efbcb4dfbd94b04265/gallery_small',
      name: 'Jumeirah Lake Towers',
      tags: ['Restaurants', 'Lakes', 'Parks', 'Cafes'],
      url: 'https://www.propertyfinder.ae/en/community-guides/dubai/jumeirah-lake-towers',
    },
    {
      id: 67,
      image: '/images/pf_community_guides/moment/b33e4d940549e6f73c455b5456f0c5e9c97f56cf/gallery_small',
      name: 'Jumeirah Beach Residencey',
      tags: ['Restaurants', 'Retail', 'Beach', 'Cafes'],
      url: 'https://www.propertyfinder.ae/en/community-guides/dubai/dubai-sports-city',
    },
    {
      id: 55,
      image: '/images/pf_community_guides/moment/b33e4d940549e6f73c455b5456f0c5e9c97f56cf/gallery_small',
      name: 'Dubai Sports City',
      tags: ['Towers', 'Stadium', 'Peaceful', 'Sporty'],
      url: 'https://www.propertyfinder.ae/en/community-guides/dubai/dubai-sports-city',
    },
  ],
  ar: [
    {
      id: 50,
      image: '/images/pf_community_guides/moment/ab0052e0efcb94559c9be23133126b89f579e87f/gallery_small',
      name: 'دبي مارينا',
      tags: ['ناطحات سحاب', 'مارينا', 'يخوت', 'مطاعم'],
      url: 'https://www.propertyfinder.ae/en/community-guides/dubai/dubai-marina',
    },
    {
      id: 41,
      image: '/images/pf_community_guides/moment/861b26723e42daa0380f43eaef2800fb7d375a18/gallery_small',
      name: 'دبي وسط المدينة',
      tags: ['معماري', 'معالم', 'تسوّق', 'مطاعم'],
      url: 'https://www.propertyfinder.ae/en/community-guides/dubai/downtown-dubai',
    },
    {
      id: 36,
      image: '/images/pf_community_guides/moment/1c80a433c1c0b3a2ea56bba598cdb4751e64f748/gallery_small',
      name: 'الخليج التجاري',
      tags: ['ابراج', 'موقع مركزي', 'مطاعم'],
      url: 'https://www.propertyfinder.ae/en/community-guides/dubai/business-bay',
    },
    {
      id: 86,
      image: '/images/pf_community_guides/moment/168ee7e927aada0481e476c608830e6fe33e0c05/gallery_small',
      name: 'نخلة الجميرا',
      tags: ['فنادق', 'شواطئ', 'أنشطة مائية', 'نوادي شاطئية'],
      url: 'https://www.propertyfinder.ae/en/community-guides/dubai/palm-jumeirah',
    },
    {
      id: 73,
      image: '/images/pf_community_gui des/moment/2b9f6c509f278e0837307670dad05fa90b74a37a/gallery_small',
      name: 'قرية الجميرا سركل',
      tags: ['هادئ', 'حدائق', 'مطاعم'],
      url: 'https://www.propertyfinder.ae/en/community-guides/dubai/jumeirah-village-circle',
    },
    {
      id: 71,
      image: '/images/pf_community_guides/moment/f303311ba66c05463a5846efbcb4dfbd94b04265/gallery_small',
      name: 'أبراج بحيرة الجميرا',
      tags: ['مطاعم', 'بحيرات', 'حدائق', 'مقاهي'],
      url: 'https://www.propertyfinder.ae/en/community-guides/dubai/jumeirah-lake-towers',
    },
    {
      id: 67,
      image: '/images/pf_community_guides/moment/b33e4d940549e6f73c455b5456f0c5e9c97f56cf/gallery_small',
      name: 'مساكن شاطئ الجميرا',
      tags: ['مطاعم', 'محلات', 'شاطئ', 'مقاهي'],
      url: 'https://www.propertyfinder.ae/en/community-guides/dubai/dubai-sports-city',
    },
    {
      id: 55,
      image: '/images/pf_community_guides/moment/b33e4d940549e6f73c455b5456f0c5e9c97f56cf/gallery_small',
      name: 'مدينة دبي الرياضية',
      tags: ['ابراج', 'استاد', 'هادئ', 'رياضي'],
      url: 'https://www.propertyfinder.ae/en/community-guides/dubai/dubai-sports-city',
    },
  ],
};
export const locationSet2: Record<string, CommunityGuideCardTemplatePropsInterface[]> = {
  en: [
    {
      id: 34,
      image: '/images/pf_community_guides/moment/8059deb28f7c360b0985c02273c80a0f0ffe886c/gallery_small',
      name: 'Arabian Ranches',
      tags: ['Parks', 'Green', 'Lakes', 'Outdoors'],
      url: 'https://www.propertyfinder.ae/en/community-guides/dubai/arabian-ranches',
    },
    {
      id: 66,
      image: '/images/pf_community_guides/moment/0dd942c07a9eda17372be2f3314793bbddc437b3/gallery_small',
      name: 'Jumeirah',
      tags: ['Beach', 'Relaxed', 'Quiet', 'Schools'],
      url: 'https://www.propertyfinder.ae/en/community-guides/dubai/jumeirah',
    },
    {
      id: 129,
      image: '/images/pf_community_guides/moment/79695515a99e97caae0f2cd15270e2f34b266452/gallery_small',
      name: 'DAMAC Hills (Akoya by DAMAC)',
      tags: ['Golf course', 'Suburbs', 'Active lifestyle'],
      url: 'https://www.propertyfinder.ae/en/community-guides/dubai/damac-hills-akoya-by-damac',
    },
    {
      id: 13,
      image: '/images/pf_community_guides/moment/8fa1beac5e9c521e8a8c3efca2f1365a66d76049/gallery_small',
      name: 'Al Barsha',
      tags: ['Mall', 'Parks', 'Restaurants', 'Spas'],
      url: 'https://www.propertyfinder.ae/en/community-guides/dubai/al-barsha',
    },
    {
      id: 89,
      image: '/images/pf_community_guides/moment/4c4f4177cc91049c84d2caa84efcf8296b1ec6d8/gallery_small',
      name: 'The Springs',
      tags: ['Lakes', 'Parks', 'Outdoors', 'Pet Owners'],
      url: 'https://www.propertyfinder.ae/en/community-guides/dubai/the-springs',
    },
    {
      id: 131,
      image: '/images/pf_community_guides/moment/a33514c2a83031025892aca14862e88d10442f73/gallery_small',
      name: 'Town Square',
      tags: ['Outdoors', 'Suburbs', 'Pet-friendly'],
      url: 'https://www.propertyfinder.ae/en /community-guides/dubai/town-square',
    },
    {
      id: 73,
      image: '/images/pf_community_guides/moment/2b9f6c509f278e0837307670dad05fa90b74a37a/gallery_small',
      name: 'Jumeirah Village Circle',
      tags: ['Quiet', 'Parks', 'Restaurants'],
      url: 'https://www.propertyfinder.ae/en/community-guides/dubai/jumeirah-village-circle',
    },
    {
      id: 86,
      image: '/images/pf_community_guides/moment/168ee7e927aada0481e476c608830e6fe33e0c05/gallery_small',
      name: 'Palm Jumeirah',
      tags: ['Hotels', 'Beaches', 'Water activities', 'Beach clubs'],
      url: 'https://www.propertyfinder.ae/en/community-guides/dubai/palm-jumeirah',
    },
  ],
  ar: [
    {
      id: 34,
      image: '/images/pf_community_guides/moment/8059deb28f7c360b0985c02273c80a0f0ffe886c/gallery_small',
      name: 'المرابع العربية',
      tags: ['حدائق', 'خضراء', 'بحيرات', 'في الهواء الطلق'],
      url: 'https://www.propertyfinder.ae/en/community-guides/dubai/arabian-ranches',
    },
    {
      id: 66,
      image: '/images/pf_community_guides/moment/0dd942c07a9eda17372be2f3314793bbddc437b3/gallery_small',
      name: 'الجميرا',
      tags: ['شاطئ', 'استرخاء', 'هادئ', 'مدارس'],
      url: 'https://www.propertyfinder.ae/en/community-guides/dubai/jumeirah',
    },
    {
      id: 129,
      image: '/images/pf_community_guides/moment/79695515a99e97caae0f2cd15270e2f34b266452/gallery_small',
      name: 'داماك هيلز (أكويا باي داماك)',
      tags: ['ملعب جولف', 'ضواحي', 'نمط حياة نشيط'],
      url: 'https://www.propertyfinder.ae/en/community-guides/dubai/damac-hills-akoya-by-damac',
    },
    {
      id: 13,
      image: '/images/pf_community_guides/moment/8fa1beac5e9c521e8a8c3efca2f1365a66d76049/gallery_small',
      name: 'البرشاء',
      tags: ['مول', 'حدائق', 'مطاعم', 'نوادي صحية'],
      url: 'https://www.propertyfinder.ae/en/community-guides/dubai/al-barsha',
    },
    {
      id: 89,
      image: '/images/pf_community_guides/moment/4c4f4177cc91049c84d2caa84efcf8296b1ec6d8/gallery_small',
      name: 'الينابيع',
      tags: ['بحيرات', 'حدائق', 'في الهواء الطلق', 'أصحاب الحيوانات الأليفة'],
      url: 'https://www.propertyfinder.ae/en/community-guides/dubai/the-springs',
    },
    {
      id: 131,
      image: '/images/pf_community_guides/moment/a33514c2a83031025892aca14862e88d10442f73/gallery_small',
      name: 'تاون سكوار',
      tags: ['في الهواء الطلق', 'ضواحي', 'حيوان أليف'],
      url: 'https://www.propertyfinder.ae/en /community-guides/dubai/town-square',
    },
    {
      id: 73,
      image: '/images/pf_community_gui des/moment/2b9f6c509f278e0837307670dad05fa90b74a37a/gallery_small',
      name: 'قرية الجميرا سركل',
      tags: ['هادئ', 'حدائق', 'مطاعم'],
      url: 'https://www.propertyfinder.ae/en/community-guides/dubai/jumeirah-village-circle',
    },
    {
      id: 86,
      image: '/images/pf_community_guides/moment/168ee7e927aada0481e476c608830e6fe33e0c05/gallery_small',
      name: 'نخلة الجميرا',
      tags: ['فنادق', 'شواطئ', 'أنشطة مائية', 'نوادي شاطئية'],
      url: 'https://www.propertyfinder.ae/en/community-guides/dubai/palm-jumeirah',
    },
  ],
};
export const locationSet3: Record<string, CommunityGuideCardTemplatePropsInterface[]> = {
  en: [
    {
      id: 40,
      image: '/images/pf_community_guides/moment/248dbeccaabf972aa96b9d87aa420e4dafc9a0ae/gallery_small',
      name: 'Discovery Gardens',
      tags: ['Affordable', 'Walkways', 'Lush', 'Outdoors'],
      url: 'https://www.propertyfinder.ae/en/community-guides/dubai/discovery-gardens',
    },

    {
      id: 73,
      image: '/images/pf_community_gui des/moment/2b9f6c509f278e0837307670dad05fa90b74a37a/gallery_small',
      name: 'Jumeirah Village Circle',
      tags: ['Quiet', 'Parks', 'Restaurants'],
      url: 'https://www.propertyfinder.ae/en/community-guides/dubai/jumeirah-village-circle',
    },
    {
      id: 54,
      image: '/images/pf_community_guides/moment/63c98e87a4803e4e8cb7f6c79b8dea19c901bd9c/gallery_small',
      name: 'Dubai Silicon Oasis',
      tags: ['Peaceful', 'Suburbs', 'Restaurants'],
      url: 'https://www.propertyfinder.ae/en/community-guides/dubai/dubai-silicon-oasis',
    },
    {
      id: 78,
      image: '/images/pf_community_guides/moment/e7f20c7d0cf7ae7a5b27f012d885fc86494fc1aa/gallery_small',
      name: 'Mirdif',
      tags: ['Peaceful', 'Parks', 'Families'],
      url: 'https://www.propertyfinder.ae/en/community-guides/dubai/mirdif',
    },
    {
      id: 63,
      image: '/images/pf_community_guides/moment/26d9489d37d5e90efe8298ed15582c9626f7239c/gallery_small',
      name: 'International City',
      tags: ['Restaurants', 'Affordable', 'Cafes'],
      url: 'https://www.propertyfinder.ae/en/community-guides/dubai/international-city',
    },
    {
      id: 24,
      image: '/images/pf_community_guides/moment/126803b5f59ff14cadf88241977eb5bc940fd8ad/gallery_small',
      name: 'Al Quoz',
      tags: ['Art', 'Cafes', 'Affordable'],
      url: 'https://www.propertyfinder.ae/en/community-guides/dubai/al-quoz',
    },
    {
      id: 38,
      image: '/images/pf_community_guides/moment/03127fd8de01c26d08f367ddc0ca32bd3c60437f/gallery_small',
      name: 'Deira',
      tags: ['Restaurants', 'Culture', 'History', 'Cafes'],
      url: 'https://www.propertyfinder.ae/en/community-guides/dubai/deira',
    },
    {
      id: 98,
      image: '/images/pf_community_guides/moment/f0d1045e514d55159bf22dbf768e1dc3ed107ffb/gallery_small',
      name: 'Umm Suqeim',
      tags: ['Quiet', 'Beach', 'Central location'],
      url: 'https://www.propertyfinder.ae/en/community-guides/dubai/umm-suqeim',
    },
  ],
  ar: [
    {
      id: 40,
      image: '/images/pf_community_guides/moment/248dbeccaabf972aa96b9d87aa420e4dafc9a0ae/gallery_small',
      name: 'ديسكوفري غاردنز',
      tags: ['سعر معقول', 'مماشٍ', 'مشجرة', 'في الهواء الطلق'],
      url: 'https://www.propertyfinder.ae/en/community-guides/dubai/discovery-gardens',
    },
    {
      id: 73,
      image: '/images/pf_community_gui des/moment/2b9f6c509f278e0837307670dad05fa90b74a37a/gallery_small',
      name: 'قرية الجميرا سركل',
      tags: ['هادئ', 'حدائق', 'مطاعم'],
      url: 'https://www.propertyfinder.ae/en/community-guides/dubai/jumeirah-village-circle',
    },
    {
      id: 54,
      image: '/images/pf_community_guides/moment/63c98e87a4803e4e8cb7f6c79b8dea19c901bd9c/gallery_small',
      name: 'واحة السيليكون',
      tags: ['هادئ', 'ضواحي', 'مطاعم'],
      url: 'https://www.propertyfinder.ae/en/community-guides/dubai/dubai-silicon-oasis',
    },
    {
      id: 78,
      image: '/images/pf_community_gui des/moment/2b9f6c509f278e0837307670dad05fa90b74a37a/gallery_small',
      name: 'مردف',
      tags: ['هادئ', 'حدائق', 'عائلات'],
      url: 'https://www.propertyfinder.ae/en/community-guides/dubai/mirdif',
    },
    {
      id: 63,
      image: '/images/pf_community_guides/moment/26d9489d37d5e90efe8298ed15582c9626f7239c/gallery_small',
      name: 'المدينة الدولية',
      tags: ['مطاعم', 'سعر معقول', 'مقاهي'],
      url: 'https://www.propertyfinder.ae/en/community-guides/dubai/international-city',
    },
    {
      id: 24,
      image: '/images/pf_community_guides/moment/126803b5f59ff14cadf88241977eb5bc940fd8ad/gallery_small',
      name: 'القوز',
      tags: ['فن', 'مقاهي', 'سعر معقول'],
      url: 'https://www.propertyfinder.ae/en/community-guides/dubai/al-quoz',
    },
    {
      id: 38,
      image: '/images/pf_community_guides/moment/03127fd8de01c26d08f367ddc0ca32bd3c60437f/gallery_small',
      name: 'ديرة',
      tags: ['مطاعم', 'ثقافة', 'تاريخ', 'مقاهي'],
      url: 'https://www.propertyfinder.ae/en/community-guides/dubai/deira',
    },
    {
      id: 98,
      image: '/images/pf_community_guides/moment/f0d1045e514d55159bf22dbf768e1dc3ed107ffb/gallery_small',
      name: 'أم سقيم',
      tags: ['هادئ', 'شاطئ', 'موقع مركزي'],
      url: 'https://www.propertyfinder.ae/en/community-guides/dubai/umm-suqeim',
    },
  ],
};
export const locationSet4: Record<string, CommunityGuideCardTemplatePropsInterface[]> = {
  en: [
    {
      id: 24,
      image: '/images/pf_community_guides/moment/126803b5f59ff14cadf88241977eb5bc940fd8ad/gallery_small',
      name: 'Al Quoz',
      tags: ['Art', 'Cafes', 'Affordable'],
      url: 'https://www.propertyfinder.ae/en/community-guides/dubai/al-quoz',
    },
    {
      id: 38,
      image: '/images/pf_community_guides/moment/03127fd8de01c26d08f367ddc0ca32bd3c60437f/gallery_small',
      name: 'Deira',
      tags: ['Restaurants', 'Culture', 'History', 'Cafes'],
      url: 'https://www.propertyfinder.ae/en/community-guides/dubai/deira',
    },
    {
      id: 36,
      image: '/images/pf_community_guides/moment/1c80a433c1c0b3a2ea56bba598cdb4751e64f748/gallery_small',
      name: 'Business Bay',
      tags: ['Towers', 'Central location', 'Restaurants'],
      url: 'https://www.propertyfinder.ae/en/community-guides/dubai/business-bay',
    },
    {
      id: 71,
      image: '/images/pf_community_guides/moment/f303311ba66c05463a5846efbcb4dfbd94b04265/gallery_small',
      name: 'Jumeirah Lake Towers',
      tags: ['Restaurants', 'Lakes', 'Parks', 'Cafes'],
      url: 'https://www.propertyfinder.ae/en/community-guides/dubai/jumeirah-lake-towers',
    },
    {
      id: 39,
      image: '/images/pf_community_guides/moment/d9f7211c1ec95ad59ebf47bf8428b7e7dc7d870f/gallery_small',
      name: 'DIFC',
      tags: ['Restaurants', 'Clubs', 'High-rise towers', 'Bars'],
      url: 'https://www.propertyfinder.ae/en/community-guides/dubai/difc',
    },
  ],
  ar: [
    {
      id: 24,
      image: '/images/pf_community_guides/moment/126803b5f59ff14cadf88241977eb5bc940fd8ad/gallery_small',
      name: 'القوز',
      tags: ['فن', 'مقاهي', 'سعر معقول'],
      url: 'https://www.propertyfinder.ae/en/community-guides/dubai/al-quoz',
    },
    {
      id: 38,
      image: '/images/pf_community_guides/moment/03127fd8de01c26d08f367ddc0ca32bd3c60437f/gallery_small',
      name: 'ديرة',
      tags: ['مطاعم', 'ثقافة', 'تاريخ', 'مقاهي'],
      url: 'https://www.propertyfinder.ae/en/community-guides/dubai/deira',
    },
    {
      id: 36,
      image: '/images/pf_community_guides/moment/1c80a433c1c0b3a2ea56bba598cdb4751e64f748/gallery_small',
      name: 'الخليج التجاري',
      tags: ['ابراج', 'موقع مركزي', 'مطاعم'],
      url: 'https://www.propertyfinder.ae/en/community-guides/dubai/business-bay',
    },
    {
      id: 71,
      image: '/images/pf_community_guides/moment/f303311ba66c05463a5846efbcb4dfbd94b04265/gallery_small',
      name: 'أبراج بحيرة الجميرا',
      tags: ['مطاعم', 'بحيرات', 'حدائق', 'مقاهي'],
      url: 'https://www.propertyfinder.ae/en/community-guides/dubai/jumeirah-lake-towers',
    },
    {
      id: 39,
      image: '/images/pf_community_guides/moment/d9f7211c1ec95ad59ebf47bf8428b7e7dc7d870f/gallery_small',
      name: 'مركز دبي المالي العالمي',
      tags: ['مطاعم', 'مطاعم', 'ابراج', 'مقاهي'],
      url: 'https://www.propertyfinder.ae/en/community-guides/dubai/difc',
    },
  ],
};
export const PopularLocations: Record<string, Record<string, CommunityGuideCardTemplatePropsInterface[]>> = {
  en: {
    // Apartment
    1: locationSet1.en,
    // Penthouse
    20: locationSet1.en,
    // Duplex
    24: locationSet1.en,
    // Full Floor
    18: locationSet1.en,
    // Half Floor
    29: locationSet1.en,
    // Hotel & Hotel Apartment
    45: locationSet1.en,

    // Villa
    35: locationSet2.en,
    // Townhouse
    22: locationSet2.en,
    // Bungalow
    31: locationSet2.en,

    // Compound
    42: locationSet3.en,
    // Whole Building"
    10: locationSet3.en,
    // Bulk Rent Unit
    34: locationSet3.en,
    // Bulk Sale Unit"
    30: locationSet3.en,
    // Land
    5: locationSet3.en,

    // Office Space
    4: locationSet4.en,
    // Retail
    27: locationSet4.en,
    // Warehouse
    13: locationSet4.en,
    // Shop
    21: locationSet4.en,
    // Show Room
    12: locationSet4.en,
    // Factory
    44: locationSet4.en,
    // Labor Camp
    11: locationSet4.en,
    // Staff Accommodation
    43: locationSet4.en,
    // Business Centre
    48: locationSet4.en,
    // Co-working space
    49: locationSet4.en,
    // Farm
    50: locationSet4.en,
  },
  ar: {
    // Apartment
    1: locationSet1.ar,
    // Penthouse
    20: locationSet1.ar,
    // Duplex
    24: locationSet1.ar,
    // Full Floor
    18: locationSet1.ar,
    // Half Floor
    29: locationSet1.ar,
    // Hotel & Hotel Apartment
    45: locationSet1.ar,

    // Villa
    35: locationSet2.ar,
    // Townhouse
    22: locationSet2.ar,
    // Bungalow
    31: locationSet2.ar,

    // Compound
    42: locationSet3.ar,
    // Whole Building"
    10: locationSet3.ar,
    // Bulk Rent Unit
    34: locationSet3.ar,
    // Bulk Sale Unit"
    30: locationSet3.ar,
    // Land
    5: locationSet3.ar,

    // Office Space
    4: locationSet4.ar,
    // Retail
    27: locationSet4.ar,
    // Warehouse
    13: locationSet4.ar,
    // Shop
    21: locationSet4.ar,
    // Show Room
    12: locationSet4.ar,
    // Factory
    44: locationSet4.ar,
    // Labor Camp
    11: locationSet4.ar,
    // Staff Accommodation
    43: locationSet4.ar,
    // Business Centre
    48: locationSet4.ar,
    // Co-working space
    49: locationSet4.ar,
    // Farm
    50: locationSet4.ar,
  },
};
