import { domQuerySelector } from 'pf-frontend-common/src/helper/dom/query-selector';

import { preactMarkup } from 'common/helper/preact/markup';
import { BottomNavigationAsyncComponent } from 'mobile/module/bottom-navigation/async.component';

export function BottomNavigationService(): void {
  const element = domQuerySelector(document.body, '[data-qs=bottom-navigation-container]');
  preactMarkup(element, {
    BottomNavigationAsyncComponent,
  });
}
