export const propertySerpLightStatsGtmStepChange = (
  pageType: string,
  step: 'Next' | 'Back' | 'Done',
  targetScreen: string
) => ({
  event: 'customEvent',
  eventCategory: 'Light Search',
  eventAction: 'Light Search - Step - Click',
  eventLabel: `${pageType} - Light Search - Step - Click - ${step} - ${targetScreen}`,
});
