import { FunctionComponent } from 'preact';

import { preactMarkup } from 'common/helper/preact/markup';
import { HomePageHighlightedFeaturesComponent } from 'common/module/home-page-highlighted-features/component';
import { NewEverydayComponent } from 'common/module/new-everyday/component';
import { SuperAgentSliderComponent } from 'common/module/super-agent/slider/component';
import { FilterService } from 'common/service/filter/service';
import { RecentSearchesService } from 'common/service/recent-searches/service';
import { EmailAlertUnsubscribeViewFactory } from 'mobile/module/email-alert/unsubscribe/view.factory';
import { HomePageSearchBoxComponent } from 'mobile/module/home-page/search-box/component';
import { HomePageView } from 'mobile/module/home-page/view';
import { PropertySerpLightComponent } from 'mobile/module/property/serp/light/component';
import { RecentSearchesComponent } from 'mobile/module/recent-searches/component';
import { HomePageSearchBoxService } from 'mobile/service/home-page-search-box/service';
import { PropertySerpLightViewStoreService } from 'mobile/service/property-serp-light-view-store/service';

import { AppCardComponent } from '../app-card/component';
import { NativeAppDrawerAsyncComponent } from '../native-app-drawer/async.component';
import { HomePageAgentHubComponent } from './agent-hub/component';
import { HomePageListYourPropertyWithUsComponent } from './agent-hub/list-with-us/component';
import { HomePageFindMoreComponent } from './find-more/component';
import { HomePageHeroContentComponent } from './hero-content/component';
import { HomePageLinkGroupsComponent } from './link-groups/component';
import { HomePageUsefulToolsComponent } from './useful-tools/component';

export function HomePageViewFactory(): HomePageView {
  return new HomePageView(
    EmailAlertUnsubscribeViewFactory(),
    preactMarkup,
    PropertySerpLightViewStoreService(),
    FilterService(),
    HomePageSearchBoxService(),
    RecentSearchesService(),
    {
      PropertySerpLightComponent,
      HomePageSearchBoxComponent,
      NewEverydayComponent,
      HomePageFindMoreComponent,
      HomePageUsefulToolsComponent,
      HomePageAgentHubComponent,
      HomePageListYourPropertyWithUsComponent,
      HomePageLinkGroupsComponent,
      RecentSearchesComponent: RecentSearchesComponent as FunctionComponent,
      HomePageHighlightedFeaturesComponent,
      AppCardComponent,
      SuperAgentSliderComponent,
      HomePageHeroContentComponent,
      NativeAppDrawerComponent: NativeAppDrawerAsyncComponent,
    }
  );
}
