import { ViewBasicInterface } from 'pf-frontend-common/src/module/view/basic.interface';

import { PreactMarkupComponentsInterface } from 'common/helper/preact/markup/components.interface';
import { EmailAlertUnsubscribeView } from 'common/module/email-alert/unsubscribe/view';
import { FilterStore } from 'common/module/filter/store';
import { PropertySerpLightViewStore } from 'common/module/property/serp/light/view-store';
import { RecentSearchesStore } from 'common/module/recent-searches/store';
import { preactMarkup } from 'common/typings/preact-markup';
import { HomePageSearchBoxViewStore } from 'mobile/module/home-page/search-box/view-store';
import { HomePageViewOptionsInterface } from 'mobile/module/home-page/view-options.interface';

export class HomePageView implements ViewBasicInterface {
  /**
   * Constructor
   */
  public constructor(
    private emailAlertUnsubscribeView: EmailAlertUnsubscribeView,
    private preactMarkupHelper: preactMarkup,
    private lightViewStoreService: PropertySerpLightViewStore,
    private filterService: FilterStore,
    private searchBoxViewStore: HomePageSearchBoxViewStore,
    private recentSearchesStore: RecentSearchesStore,
    private components: PreactMarkupComponentsInterface
  ) {}

  /**
   * @inheritDoc
   */
  public initialize(options: HomePageViewOptionsInterface): void {
    this.filterService.initialize(options.search.form);

    this.lightViewStoreService.initialize({
      serverFilter: options.search.form,
      searchUri: options.search.uri,
    });

    this.searchBoxViewStore.initialize({
      serverFilter: options.search.form,
      searchUri: options.search.uri,
    });

    this.initEmailAlertUnsubscribe();

    // Initialize preact markup
    this.preactMarkupHelper(options.element, this.components);

    this.recentSearchesStore.init();
  }

  /**
   * @inheritDoc
   */
  public render(): void {
    // Render subviews
    this.renderEmailAlertUnsubscribe();
  }

  /**
   * Init email alert unsubscribe
   */
  private initEmailAlertUnsubscribe(): void {
    this.emailAlertUnsubscribeView.initialize();
  }

  /**
   * Render email alert unsubscribe view
   */
  private renderEmailAlertUnsubscribe(): void {
    this.emailAlertUnsubscribeView.render();
  }
}
