import { preactConnectStore } from 'common/module/preact/connect-store';
import { HomePageSearchBoxService } from 'mobile/service/home-page-search-box/service';

import { HomePageSearchBoxTemplate } from './template';
import { HomePageSearchBoxTemplatePropsInterface } from './template-props.interface';
import { HomePageSearchBoxViewStore } from './view-store';

export const HomePageSearchBoxComponent = preactConnectStore<
  HomePageSearchBoxViewStore,
  HomePageSearchBoxTemplatePropsInterface
>(HomePageSearchBoxService, (store) => ({
  ...store.getState(),
}))(HomePageSearchBoxTemplate);
