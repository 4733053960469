import { FunctionalComponent, h } from 'preact';

import { highlightString } from 'common/helper/highlight/string';
import { AutocompleteSuggestionTemplatePropsInterface } from 'common/module/autocomplete/suggestion/template-props.interface';

export const AutocompleteSuggestionTemplate: FunctionalComponent<AutocompleteSuggestionTemplatePropsInterface> = (
  props
) => (
  <div
    className={`
    autocomplete__suggestion
    ${props.cssClass || ''}
  `}
    onClick={(e) =>
      props.onSelectResultItem(e, {
        id: props.suggestion.id,
        sectionId: props.section.id,
        text: props.suggestion.text,
        description: props.suggestion.description,
      })
    }
  >
    <div
      className='autocomplete__suggestion-title'
      dangerouslySetInnerHTML={{
        __html: highlightString(props.searchString, props.suggestion.text, {
          wrapper: `<div class='autocomplete__highlight'>$1</div>`,
        }),
      }}
    />
    <div className='autocomplete__suggestion-description'>{props.suggestion.description}</div>
  </div>
);
