import { Component, h } from 'preact';

import { LazyLoadingPropsInterface } from 'common/module/lazy-loading/props.interface';

export class LazyLoadingComponent extends Component<LazyLoadingPropsInterface, { isVisible: boolean }> {
  /**
   * Intersection observer config
   */
  private readonly options: IntersectionObserverInit;

  /**
   * Default observer config
   */
  private readonly defaultOptions: IntersectionObserverInit = {
    root: null,
    rootMargin: '0px 0px 0px 0px',
    threshold: [0],
  };

  /**
   * Intersection observer instance
   */
  private observer: IntersectionObserver;

  /**
   * Constructor
   */
  constructor(props: LazyLoadingPropsInterface) {
    super(props);

    this.options = {
      root: props?.options?.root || this.defaultOptions.root,
      rootMargin: props?.options?.rootMargin || this.defaultOptions.rootMargin,
      threshold: props?.options?.threshold || this.defaultOptions.threshold,
    };

    // Init the state
    this.state = {
      isVisible: !!props.visible,
    };
  }

  /**
   * @inheritDoc
   */
  public componentDidMount(): void {
    this.observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        // If the element is visible
        if (entry.isIntersecting || this.state.isVisible) {
          // Element is in view port
          this.setState({
            ...this.state,
            isVisible: true,
          });

          // Stop observer subscription
          this.observer.unobserve(entry.target);

          // Execute change callback
          this.onChange(entry);
        }
      });
    }, this.options);

    this.observer.observe(this.base as HTMLElement);
  }

  /**
   * @inheritDoc
   */
  public componentWillUnmount(): void {
    if (this.observer) {
      this.observer.disconnect();
    }
  }

  /**
   * @inheritDoc
   */
  public render(): preact.JSX.Element {
    const { placeholder } = this.props;

    return (
      <div className={this.props.className}>
        {this.state.isVisible ? this.props.children : placeholder ? placeholder : null}
      </div>
    );
  }

  /**
   * On change element visibility handler
   */
  private onChange(entry: IntersectionObserverEntry): void {
    if (this.props.onChange) {
      this.props.onChange(entry);
    }
  }
}
