import { FunctionalComponent, h } from 'preact';

import { TagTemplatePropsInterface } from 'common/module/tag/template-props.interface';

export const TagTemplate: FunctionalComponent<TagTemplatePropsInterface> = (props) => (
  <div
    className={`
    tag
    ${props.isArrowBottomVisible ? 'tag__arrow-bottom' : ''}
    ${props.tagCssClass ? props.tagCssClass : ''}
  `}
    onClick={props.onClick}
  >
    {props.children}
    {props.isCloseIconVisible ? <div className='tag__close'>x</div> : ''}
  </div>
);
