import { FunctionalComponent, h } from 'preact';

import { IconTemplatePropsInterface } from 'common/module/icon/template-props.interface';

// tslint:disable:max-line-length
export const IconThickHistory: FunctionalComponent<IconTemplatePropsInterface> = (props) => {
  const { clipped = false } = props;

  return (
    <svg
      viewBox={clipped ? '4.5 4.5 14.97 14.64' : '0 0 24 24'}
      height={clipped ? undefined : '24'}
      width={clipped ? undefined : '24'}
      className={props.class}
    >
      <path
        fill='currentColor'
        fillRule='evenodd'
        clipRule='evenodd'
        d='m9.1 7.969.55 1.358a.4.4 0 0 1-.475.536l-3.418-.906a.5.5 0 0 1-.3-.741l1.829-3.023a.4.4 0 0 1 .713.057l.534 1.318a6.731 6.731 0 0 1 3.211-.81c3.731 0 6.756 3.02 6.756 6.746 0 3.726-3.025 6.746-6.756 6.746a6.74 6.74 0 0 1-4.777-1.976.749.749 0 0 1 0-1.06.751.751 0 0 1 1.062 0 5.239 5.239 0 0 0 3.715 1.537A5.25 5.25 0 0 0 17 12.504a5.25 5.25 0 0 0-5.255-5.247A5.243 5.243 0 0 0 9.1 7.97ZM5 12.926a.75.75 0 0 1 1.499-.092c.026.423.103.838.228 1.236a.75.75 0 1 1-1.433.447A6.717 6.717 0 0 1 5 12.927Zm7.5-.892 2.102 1.64a.749.749 0 1 1-.925 1.18l-1.971-1.538c-.128-.099-.707-.503-.707-1.062V9.756a.75.75 0 0 1 1.501 0v2.278Z'
      />
    </svg>
  );
};
