import { PropertyCategoryIdentifierAdapterFactory } from 'common/data/property-category/identifier/adapter.factory';
import { BrowserCookieService } from 'common/service/browser-cookie/service';
import { FilterService } from 'common/service/filter/service';
import { StatsTealiumProviderService } from 'common/service/stats-tealium-provider/service';

import { RecentSearchesStore } from './store';

export function RecentSearchesStoreFactory(): RecentSearchesStore {
  return new RecentSearchesStore(
    BrowserCookieService(),
    FilterService(),
    PropertyCategoryIdentifierAdapterFactory(),
    StatsTealiumProviderService()
  );
}
