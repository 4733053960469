import { Fragment, FunctionalComponent, h } from 'preact';
import { useState } from 'preact/hooks';

import { configGetSettings } from 'common/helper/config/get-settings';
import { i18nTranslate } from 'common/helper/i18n/translate';
import { ButtonComponent } from 'common/module/button/component';
import { ButtonComponentTypeEnum } from 'common/module/button/component-type.enum';
import { FilterParamsEnum } from 'common/module/filter/params.enum';
import { IconMagnifierTemplate } from 'common/module/icon';
import { Modal2Component } from 'common/module/modal2/component';
import { PropertySerpLightStatsGtmAutocompleteInteraction } from 'common/module/property/serp/light/stats/gtm/autocomplete-interaction';
import { propertySerpLightStatsGtmStepChange } from 'common/module/property/serp/light/stats/gtm/step-change';
import { PropertySerpLightWizardStepLocationTemplate } from 'mobile/module/property/serp/light/wizard/step/location/template';
import { PropertySerpLightWizardTemplatePropsInterface } from 'mobile/module/property/serp/light/wizard/template-props.interface';

const steps = [{ component: PropertySerpLightWizardStepLocationTemplate, name: 'Location' }];

export const PropertySerpLightWizardTemplate: FunctionalComponent<PropertySerpLightWizardTemplatePropsInterface> = (
  props
) => {
  // TODO-FE[TPNX-1995] - Move the state from the template to the component
  const [isOpened, setIsOpened] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [step, setStep] = useState(0);
  const { gtm } = configGetSettings();

  const sendInteractionEvent = (isOpen: boolean) =>
    PropertySerpLightStatsGtmAutocompleteInteraction(gtm?.pageType, isOpen);

  const selectedLocationIds: string[] = props.filterParams?.[FilterParamsEnum.locationsIds]?.value || [];
  const selected = props.selected.filter((item) => selectedLocationIds.includes(item.id));
  const isLastStep = step === steps.length - 1;

  return (
    <Fragment>
      <div className='light-search__input-container'>
        <ButtonComponent
          type='button'
          cssClass='light-search__input-prefix-button'
          componentType={ButtonComponentTypeEnum.type3}
          icon={() => <IconMagnifierTemplate class='light-search__input-prefix-button-icon' />}
        />
        <input
          className='input2__input input2__input--style2 input-search__input light-search__input-search'
          placeholder={i18nTranslate('City, community or building')}
          onClick={() => {
            setIsOpened(true);
            sendInteractionEvent(true);
          }}
          onFocus={props.onFocusInput}
        />
      </div>

      {
        <Modal2Component
          contentCssClass={'commute-time-screen__modal-content'}
          cssClass='light-search__modal'
          isFullScreen
          isOpened={isOpened}
        >
          <div className='light-search__wizard'>
            <div className='light-search__wizard-body'>
              {
                <PropertySerpLightWizardStepLocationTemplate
                  {...props}
                  selected={selected}
                  onClickBack={() => {
                    const nextStep = step - 1;
                    if (step <= 0) {
                      setIsOpened(false);
                      sendInteractionEvent(false);
                    }
                    setStep(step ? nextStep : 0);
                    props.sendStatsGtm(
                      propertySerpLightStatsGtmStepChange(gtm?.pageType, 'Back', steps[nextStep]?.name || 'Home')
                    );
                  }}
                  renderNoSuggestions={i18nTranslate('multi-location-selector-no-suggestions-new')}
                />
              }
            </div>
            {!!selected.length && (
              <div className='light-search__wizard-action'>
                <ButtonComponent
                  componentType={ButtonComponentTypeEnum.type2}
                  isLoading={isLoading}
                  cssClass='light-search__wizard-action-button'
                  onClick={() => {
                    const nextStep = step + 1;
                    if (!isLastStep) {
                      setStep(nextStep);
                      props.sendStatsGtm(
                        propertySerpLightStatsGtmStepChange(gtm?.pageType, 'Next', steps[nextStep].name)
                      );
                      return;
                    }
                    setIsLoading(true);
                    // TODO-FE[TPNX-1941] - move it to separate handler based on the tracking
                    props.onApplyFilters({
                      [FilterParamsEnum.locationsIds]: {
                        value: selected.map((item) => item.id),
                      },
                    });

                    props.onShowPropertiesClick();
                  }}
                >
                  {i18nTranslate(isLastStep ? 'Show Properties' : 'Next')}
                </ButtonComponent>
              </div>
            )}
          </div>
        </Modal2Component>
      }
    </Fragment>
  );
};
