import { FunctionalComponent, h } from 'preact';

import { chooseAOrNull } from 'common/helper/choose/a-or-null';
import { i18nTranslate } from 'common/helper/i18n/translate';
import { AutocompleteSectionInterface } from 'common/module/autocomplete/section.interface';
import { Loader4Template } from 'common/module/loader/4/template';
import { preactLifecycleComponent } from 'common/module/preact/lifecycle-component';
import { TagComponent } from 'common/module/tag/component';
import { AutocompleteSectionTemplate } from 'mobile/module/autocomplete/section/template';
import { AutocompleteTemplatePropsInterface } from 'mobile/module/autocomplete/template-props.interface';

const Markup: FunctionalComponent<AutocompleteTemplatePropsInterface> = ({
  appendElement: AppendElement,
  ...props
}) => (
  <div className={`autocomplete ${props.isActive ? 'autocomplete--active' : ''}`} onClick={props.onClickAutocomplete}>
    <div className='autocomplete__input-wrapper'>
      <div
        className={`autocomplete__icon
        ${props.isActive ? 'autocomplete__icon--active' : ''}`}
      >
        <svg viewBox='0 0 16 16' className='autocomplete__icon-img'>
          <use xlinkHref='#svg-search' />
        </svg>
      </div>
      <div className='autocomplete__tags'>
        {props.selected.map((selection) => (
          <TagComponent
            tagCssClass='tag--style1 autocomplete__tag'
            isCloseIconVisible
            onClick={(e) => props.onClickTag(e, selection)}
          >
            {selection.text}
          </TagComponent>
        ))}
        {/*
          TODO-FE[1234]: take +add location from autocomplete props when merged with mobile autocomplete
        */}
        <div className={'autocomplete__add-location-area'}>
          {(!props.limit || props.limit > props.selected.length) && (
            <input
              value={props.searchString}
              ref={(el) => props.onInputRendered && props.onInputRendered(el as HTMLInputElement)}
              className='autocomplete__input autocomplete__input--short'
              onKeyUp={props.onKeyUpInput}
              onKeyDown={props.onKeyDownInput}
              onClick={props.onClickInput}
              onFocus={props.onFocusInput}
              onBlur={props.onBlurInput}
              placeholder={props.selected.length ? i18nTranslate('+ Add location') : props.placeholder || ''}
            />
          )}
          {chooseAOrNull(<AppendElement />)(!!AppendElement)}
        </div>
      </div>
      {props.isLoaderVisible && (
        <div className='autocomplete__loader'>
          <Loader4Template />
        </div>
      )}
    </div>
    <div
      className='autocomplete__dropdown'
      style={{
        display: props.isOpened && !!props.autocompleteResults.length ? 'block' : 'none',
      }}
    >
      {props.autocompleteResults.map((result: AutocompleteSectionInterface) => {
        if (props.searchString && result.id !== '1' && props.renderNoSuggestions) {
          return (
            <div key={result.id} className='autocomplete__dropdown-no-suggestions'>
              {props.renderNoSuggestions}
            </div>
          );
        }

        return (
          !!result.suggestions.length && (
            <AutocompleteSectionTemplate
              suggestionSection={result}
              searchString={props.searchString}
              onSelectResultItem={props.onSelectResultItem}
            />
          )
        );
      })}
    </div>
  </div>
);

export const AutocompleteTemplate = preactLifecycleComponent(Markup);
