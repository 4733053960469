import { FunctionalComponent, h } from 'preact';

import { AutocompleteResultInterface } from 'common/module/autocomplete/result.interface';
import { AutocompleteSectionTemplatePropsInterface } from 'common/module/autocomplete/section/template-props.interface';

import { AutocompleteSuggestionTemplate } from '../suggestion/template';

export const AutocompleteSectionTemplate: FunctionalComponent<AutocompleteSectionTemplatePropsInterface> = (props) => (
  <div className={`autocomplete__section ${props.cssClass || ''}`}>
    {props.suggestionSection.name && <div className='autocomplete__section-name'>{props.suggestionSection.name}</div>}

    <div className='autocomplete__suggestions'>
      {props.suggestionSection.suggestions.map((suggestion: AutocompleteResultInterface, index) => (
        <AutocompleteSuggestionTemplate
          section={props.suggestionSection}
          searchString={props.searchString}
          suggestion={suggestion}
          cssClass={props.suggestionCssClass}
          onSelectResultItem={props.onSelectResultItem}
        />
      ))}
    </div>
  </div>
);
