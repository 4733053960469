import { configGetSettings } from 'common/helper/config/get-settings';
import { FilterSearchUrlAdapterFactory } from 'common/module/filter/search/url/adapter.factory';
import { RecentSearchesService } from 'common/service/recent-searches/service';
import { HomePageBundleSettingsInterface } from 'mobile/entry/home-page/bundle/settings.interface';

import { RecentSearchesComponentFactory } from './component-factory';

export const RecentSearchesComponent = RecentSearchesComponentFactory({
  filterSearchUrlAdapter: FilterSearchUrlAdapterFactory(),
  recentSearchesStore: RecentSearchesService(),
  searchUri: configGetSettings<HomePageBundleSettingsInterface>().search.uri,
});
