import { Button, ButtonSizesEnum, ButtonThemesEnum } from '@propertyfinder/button';
import { FunctionalComponent, h } from 'preact';
import { useMemo } from 'preact/hooks';

import { abTestsIsVariant } from 'common/helper/ab-tests/is-variant';
import { i18nTranslate } from 'common/helper/i18n/translate';
import { StatsTealiumDataLayerConversionEventInterface } from 'common/module/stats/tealium/data-layer/conversion-event.interface';
import { StatsTealiumDataLayerEventActionEnum } from 'common/module/stats/tealium/data-layer/event-action.enum';
import { StatsTealiumDataLayerEventCategoryEnum } from 'common/module/stats/tealium/data-layer/event-category.enum';
import { StatsTealiumProviderService } from 'common/service/stats-tealium-provider/service';
import { DetectDevicePlatformService } from 'mobile/service/detect-device-platform/service';

import { useAppCardAppBannerImpression } from './app-banner-impression.hook';

export const AppCardComponent: FunctionalComponent = () => {
  const devicePlatform = useMemo(() => DetectDevicePlatformService(window.navigator.userAgent), []);

  const test115Type = useMemo(() => (abTestsIsVariant('test115', 'variantA') ? 'variantA' : 'original'), []);
  const ref = useAppCardAppBannerImpression(devicePlatform.isIos ? 'ios' : 'android', test115Type);
  const tracking = useMemo(() => StatsTealiumProviderService(), []);

  return (
    <a
      data-testid='banners-app-card'
      ref={ref}
      href={'https://smart.link/bq25qgyl1dt1w'}
      className='open-app-banner__container'
      onClick={(): void => {
        tracking.send({
          tealium_event: 'flagship',
          event_category: StatsTealiumDataLayerEventCategoryEnum.appBanner,
          event_value: '1',
          event_action: devicePlatform.isIos
            ? StatsTealiumDataLayerEventActionEnum.appBannerIosClick
            : StatsTealiumDataLayerEventActionEnum.appBannerAndroidClick,
          event_label: test115Type,
        } as StatsTealiumDataLayerConversionEventInterface);
      }}
    >
      <div className='open-app-banner__backround__figure open-app-banner__backround__figure--1' />
      <div className='open-app-banner__backround__figure open-app-banner__backround__figure--2' />
      <div className='open-app-banner__logo__container'>
        <img src={__('assets/mobile/english-logo-white.svg')} />
      </div>

      <div className='open-app-banner__content__container'>
        <p className='open-app-banner__text__header'>+100,000</p>
        <p
          className='open-app-banner__text__label'
          dangerouslySetInnerHTML={{ __html: `${i18nTranslate('nudges/open-app-label')}` }}
        />

        <Button
          type='submit'
          size={ButtonSizesEnum.SMALL}
          className={'open-app-banner__open-app__button open-app-banner__open-app__button--react'}
          theme={ButtonThemesEnum.TERTIARY}
          htmlAttributes={{ 'data-testid': 'banners-app-card-download-link' }}
        >
          {i18nTranslate('nudges/open-app')}
        </Button>
      </div>

      <div className='open-app-banner__phone-image__container'>
        {devicePlatform && devicePlatform.isIos ? (
          <img src={__('assets/mobile/open-app-ios.png')} />
        ) : (
          <img src={__('assets/mobile/open-app-android.png')} />
        )}
      </div>
    </a>
  );
};
