import { FunctionalComponent, h } from 'preact';

import { AutocompleteSectionInterface } from 'common/module/autocomplete/section.interface';
import { FilterParamsEnum } from 'common/module/filter/params.enum';
import { IconCrossTemplate } from 'common/module/icon';
import { IconBackTemplate } from 'common/module/icon/back';
import { InputSearchComponent } from 'common/module/input-search/component';
import { ChipFilterComponent } from 'library/chips-filter/component';
import { AutocompleteSectionTemplate } from 'mobile/module/autocomplete/section/template';
import { PropertySerpLightWizardStepPropsInterface } from 'mobile/module/property/serp/light/wizard/step/props.interface';

export const PropertySerpLightWizardStepLocationTemplate: FunctionalComponent<
  PropertySerpLightWizardStepPropsInterface
> = (props) => {
  const selectedLocationIds: string[] = [];
  const selectedLocations = props.selected.map((item) => {
    selectedLocationIds.push(item.id);
    return {
      text: item.text,
      value: item.id,
    };
  });

  return (
    <div className='light-search__screen'>
      <header className='light-search__header'>
        <InputSearchComponent
          value={props.searchString}
          onClickIcon={() => {
            // reset the locations
            props.onChangeFilters({
              [FilterParamsEnum.locationsIds]: { value: [] },
            });
            props.onClickBack();
          }}
          isAutoFocused
          label={props.placeholder}
          onInput={props.onKeyUpInput}
          isLoading={props.isLoaderVisible}
          icon={<IconBackTemplate class={'light-search__back-icon'} />}
        />
      </header>
      {!!selectedLocations.length && (
        <ChipFilterComponent
          selectedIcon={IconCrossTemplate}
          iconPosition='end'
          name='selected-locations'
          className='light-search__selected-list'
          options={selectedLocations}
          selected={selectedLocations}
          onCheck={(event, selectedOptions) => {
            props.onSelectResultItems(
              event,
              selectedOptions.map((item) => ({
                text: item.text,
                id: item.value,
                description: '',
                sectionId: '',
              }))
            );
          }}
        />
      )}
      <div>
        {props.autocompleteResults.map((result: AutocompleteSectionInterface) => {
          const suggestionSection = {
            ...result,
            suggestions: result.suggestions.filter((suggestion) => !selectedLocationIds.includes(suggestion.id)),
          };

          if (props.searchString && result.id !== '1' && props.renderNoSuggestions) {
            return (
              <div key={result.id} className='light-search__autocomplete-no-suggestions'>
                {props.renderNoSuggestions}
              </div>
            );
          }

          return (
            !!suggestionSection.suggestions.length && (
              <AutocompleteSectionTemplate
                suggestionSection={suggestionSection}
                searchString={props.searchString}
                onSelectResultItem={props.onSelectResultItem}
                cssClass='light-search__autocomplete-section'
              />
            )
          );
        })}
      </div>
    </div>
  );
};
