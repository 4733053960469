import { FunctionalComponent, h } from 'preact';

import { i18nTranslate } from 'common/helper/i18n/translate';
import { EmailAlertUnsubscribeTemplatePropsInterface } from 'common/module/email-alert/unsubscribe/template-props.interface';

export const EmailAlertUnsubscribeTemplate: FunctionalComponent<EmailAlertUnsubscribeTemplatePropsInterface> = (
  props
): preact.JSX.Element => {
  return (
    <div className='alert'>
      <div className='alert_header'>
        <div className='alert_heading'>{i18nTranslate('Unsubscribe Email Alert')}</div>
        <div className='alert_closebutton' onClick={props.onClickClose}>
          {i18nTranslate('Close')}
        </div>
      </div>
      <div className='success-message'>
        <span
          className='svg'
          dangerouslySetInnerHTML={{
            __html: `
            <svg viewBox='0 0 32 32' class='success-message__icon'>
              <use xlink:href='#svg-success-icon'></use>
            </svg>
          `,
          }}
        />
        <div className='success-message__text'>
          <span>{i18nTranslate('You have been successfully unsubscribed')}</span>
        </div>
      </div>
    </div>
  );
};
