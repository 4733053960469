import { FunctionComponent, h } from 'preact';

import { preactDynamicComponent } from 'common/module/preact/dynamic/component';
import { AutocompleteTemplate } from 'mobile/module/autocomplete/template';
import { AutocompleteViewStore } from 'mobile/module/autocomplete/view-store';
import { AutocompleteViewStoreService } from 'mobile/service/autocomplete-view-store/service';

import { AutocompleteComponentPropsInterface } from './component-props.interface';

const Autocomplete = preactDynamicComponent(AutocompleteTemplate);

export const AutocompleteComponent: FunctionComponent<AutocompleteComponentPropsInterface> = ({
  renderNoSuggestions,
}) => {
  return (
    <Autocomplete
      viewStore={AutocompleteViewStoreService()}
      mapState={(store: AutocompleteViewStore) => ({
        ...store.getState(),
        renderNoSuggestions,
      })}
    />
  );
};
