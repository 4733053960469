import { FunctionalComponent, h } from 'preact';

import { domClassMerge } from 'common/helper/dom/class-merge';
import { AutocompleteComponent } from 'mobile/module/autocomplete/component';

import { LocationAutocompleteTemplatePropsInterface } from './template-props.interface';

export const LocationAutocompleteTemplate: FunctionalComponent<LocationAutocompleteTemplatePropsInterface> = ({
  className,
  error,
  renderNoSuggestions,
}) => (
  <div className={domClassMerge('location-autocomplete', className, { 'location-autocomplete--error': !!error })}>
    <AutocompleteComponent renderNoSuggestions={renderNoSuggestions} />
    {error && <p className='location-autocomplete__error'>{error}</p>}
  </div>
);
