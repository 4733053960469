import { DetectDevicePlatformInterface } from './interface';

export const DetectDevicePlatformService = (userAgent: string): DetectDevicePlatformInterface => {
  const isAndroid: boolean = /Android/i.test(userAgent);
  const isIos: boolean = /iPhone|iPad|iPod/i.test(userAgent);
  const isOpera: boolean = /Opera Mini/i.test(userAgent);
  const isWindowsPhone: boolean = /Windows Phone/i.test(userAgent);
  const isMobile: boolean = isAndroid || isIos || isOpera || isWindowsPhone;
  const isWindows: boolean = /Windows NT/i.test(userAgent);
  const isMac: boolean = /Macintosh/i.test(userAgent);
  const isLinux: boolean = /Linux x86_64/i.test(userAgent);
  const isDesktop: boolean = isWindows || isMac || isLinux;

  return {
    isDesktop,
    isMobile,
    isAndroid,
    isIos,
  };
};
