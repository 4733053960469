import { FunctionalComponent, h } from 'preact';

import { configGetLanguage } from 'common/helper/config/get-language';
import { PreactAsyncComponent } from 'common/module/preact/async/component';
import { AssetLoaderCssService } from 'common/service/asset-loader-css/service';

export const NativeAppDrawerAsyncComponent: FunctionalComponent = (props) => (
  <PreactAsyncComponent
    {...props}
    component={() =>
      Promise.all([
        import(
          /* webpackChunkName: "native-app-drawer-component" */
          './component'
        ),
        AssetLoaderCssService().importBundle(__css('css/async-native-app-drawer.css')[configGetLanguage().current]),
      ]).then((response) => response[0].NativeAppDrawerComponent)
    }
  />
);
