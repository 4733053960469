import { FunctionalComponent, h } from 'preact';

import { HomePageLinksComponent } from '../links/component';

export const HomePageAgentHubComponent: FunctionalComponent<{ links: string }> = ({ links }) => (
  <HomePageLinksComponent
    className='text text--size3 link menu-secondary__list-item'
    links={JSON.parse(links)}
    subLabel={(props) => <div className='text text--size2 menu-secondary__list-item-text'>{props.children}</div>}
  />
);
