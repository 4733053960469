import { Fragment, FunctionalComponent, h } from 'preact';

import { IconThickHistory } from 'common/module/icon';

import { RecentSearchesTemplatePropsInterface } from './template-props.interface';

export const RecentSearchesTemplate: FunctionalComponent<RecentSearchesTemplatePropsInterface> = ({
  items,
  onClick,
}) => (
  <Fragment>
    {items.map((search) => (
      <div className='recent-searches__item'>
        <div className='recent-searches__item-content'>
          <h4 className='recent-searches__item-title'>{search.title}</h4>
          <p className='recent-searches__item-description'>{search.description}</p>
        </div>
        <IconThickHistory class='recent-searches__item-icon' />
        <a href={search.link} onClick={() => onClick(search.categoryId)} className='recent-searches__item-link' />
      </div>
    ))}
  </Fragment>
);
