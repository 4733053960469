import { domClassMerge } from '@propertyfinder/helpers';
import { Link } from '@propertyfinder/link';
import { WindowService } from '@propertyfinder/pf-frontend-common/src/service/window/service';
import { BrowserStorageSessionService } from 'pf-frontend-common/src/service/browser-storage-session/service';
import { FunctionComponent, h } from 'preact';
import { useCallback, useEffect, useMemo, useState } from 'preact/hooks';

import { abTestsIsVariant } from 'common/helper/ab-tests/is-variant';
import { configGetLanguage } from 'common/helper/config/get-language';
import { ConfigLanguageCodeEnum } from 'common/helper/config/language-code.enum';
import { i18nTranslate } from 'common/helper/i18n/translate';
import { StatsTealiumDataLayerEventCategoryEnum } from 'common/module/stats/tealium/data-layer/event-category.enum';
import { StatsTealiumDataLayerEventReferenceEnum } from 'common/module/stats/tealium/data-layer/event-reference.enum';
import { UserAuthDrawerAsyncComponent } from 'common/module/user/auth/async-drawer.component';
import { UserAuthTrackerFactory } from 'common/module/user/auth/tracker.factory';
import { StatsTealiumProviderService } from 'common/service/stats-tealium-provider/service';
import { UserAuthenticationService } from 'common/service/user-authentication/service';
import { BottomNavigationOnBoardingComponent } from 'mobile/module/bottom-navigation/on-boarding.component';

import { BottomNavigationComponentPropsInterface } from './component-props.interface';
import { bottomNavigationGetNavs } from './get-navs.util';

const browserStorageSessionService = BrowserStorageSessionService();
const tracking = StatsTealiumProviderService();

export const BottomNavigationComponent: FunctionComponent<BottomNavigationComponentPropsInterface> = ({ page }) => {
  const locale = configGetLanguage().current;
  const isLoggedIn = !!UserAuthenticationService().getToken();
  const [redirectUrl, setRedirectUrl] = useState<string | null>(null);
  const [modalVisible, setModalVisible] = useState(false);

  const navs = useMemo(() => bottomNavigationGetNavs(i18nTranslate, locale, browserStorageSessionService), [locale]);
  const onItemLinkClick = useCallback(
    (selectedPage: string) => {
      tracking.send({
        sendToGa: true,
        tealium_event: 'bottom_navigation_bar_click',
        event_action: 'bottom_navigation_bar_click',
        event_category: StatsTealiumDataLayerEventCategoryEnum.navigationClicks,
        event_label: selectedPage,
      });
      if (window.location) {
        const currentUrl = window.location.pathname + window.location.search;
        browserStorageSessionService.setData(page, currentUrl);
      }
    },
    [page, window.location]
  );

  useEffect(() => {
    tracking.send({
      sendToGa: true,
      tealium_event: 'bottom_navigation_bar_impression',
      event_action: 'bottom_navigation_bar_impression',
      event_category: StatsTealiumDataLayerEventCategoryEnum.bottomNavigationMenu,
    });
  }, []);

  useEffect(() => {
    if (isLoggedIn && redirectUrl) {
      WindowService().getNative().location.href = redirectUrl;
    }
  }, [isLoggedIn, redirectUrl]);

  if (locale !== ConfigLanguageCodeEnum.en) {
    return null;
  }

  return (
    <div>
      <ul className={'bottom-navigation__nav'}>
        {navs.map((item) => (
          <li key={item.key} className={'bottom-navigation__item'}>
            <Link
              href={item.url}
              htmlAttributes={{ 'data-testid': item.url }}
              onClick={(e: Event): void => {
                onItemLinkClick(item.key.split('-')[2]);
                if (item.secured && !isLoggedIn) {
                  e.preventDefault();
                  setRedirectUrl(item.url);
                  setModalVisible(true);
                  UserAuthTrackerFactory().onLoginModalOpen(
                    StatsTealiumDataLayerEventReferenceEnum.BOTTOM_NAVIGATION_BAR
                  );
                }
              }}
              className={'bottom-navigation__link'}
            />
            {item.key === page ? (
              <item.selectedIcon
                className={domClassMerge('bottom-navigation__icon', 'bottom-navigation__icon--selected')}
              />
            ) : (
              <item.icon className={domClassMerge('bottom-navigation__icon')} />
            )}
            {item.isNew && (
              <span className={'bottom-navigation__new'}>
                <img alt='assets/mobile/new.svg' src={`${__('assets/mobile/new.svg')}`} width={30} height={14} />
              </span>
            )}
            <span
              className={domClassMerge('bottom-navigation__label', {
                ['bottom-navigation__label--selected']: item.key === page,
              })}
            >
              {item.label}
            </span>
          </li>
        ))}
      </ul>
      {modalVisible && (
        <UserAuthDrawerAsyncComponent
          reference={StatsTealiumDataLayerEventReferenceEnum.BOTTOM_NAVIGATION_BAR}
          onSuccess={(): void => setModalVisible(false)}
          onClose={(): void => setModalVisible(false)}
        />
      )}
      {!abTestsIsVariant('test128', 'variantA') && <BottomNavigationOnBoardingComponent />}
    </div>
  );
};
