import { ComponentFactory, FunctionalComponent, h } from 'preact';

import { TagTemplate } from 'common/module/tag/template';
import { TagTemplatePropsInterface } from 'common/module/tag/template-props.interface';

export const TagComponent: FunctionalComponent<
  TagTemplatePropsInterface & {
    template?: ComponentFactory<TagTemplatePropsInterface>;
  }
> = ({ template: Template = TagTemplate, ...props }) => <Template {...props} />;
