import { EventEmitterFactory } from 'pf-frontend-common/src/module/event/emitter.factory';

import { configGetLanguage } from 'common/helper/config/get-language';
import { FilterService } from 'common/service/filter/service';
import { LocationAutocompleteHistoryService } from 'common/service/location-autocomplete-history/service';
import { StatsTealiumProviderService } from 'common/service/stats-tealium-provider/service';
import { PropertyAutocompleteStoreFactory } from 'mobile/module/property/autocomplete/store.factory';
import { AutocompleteViewStoreService } from 'mobile/service/autocomplete-view-store/service';

import { LocationAutocompleteViewStore } from './view-store';

export function LocationAutocompleteViewStoreFactory(): LocationAutocompleteViewStore {
  return new LocationAutocompleteViewStore(
    EventEmitterFactory(),
    PropertyAutocompleteStoreFactory(FilterService()),
    AutocompleteViewStoreService(),
    LocationAutocompleteHistoryService(),
    FilterService(),
    StatsTealiumProviderService(),
    configGetLanguage().current
  );
}
