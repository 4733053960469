import { preactConnectStore } from 'common/module/preact/connect-store';

import { LocationAutocompleteComponentPropsInterface } from './component-props.interface';
import { LocationAutocompleteTemplate } from './template';
import { LocationAutocompleteTemplatePropsInterface } from './template-props.interface';
import { LocationAutocompleteViewStore } from './view-store';
import { LocationAutocompleteViewStoreFactory } from './view-store.factory';

export const LocationAutocompleteComponent = preactConnectStore<
  LocationAutocompleteViewStore,
  LocationAutocompleteTemplatePropsInterface,
  LocationAutocompleteComponentPropsInterface
>(
  LocationAutocompleteViewStoreFactory,
  (store, { props }) => ({
    ...store.getState(),
    className: props.className,
    error: props.error,
    renderNoSuggestions: props.renderNoSuggestions,
  }),
  (store, { props }) => store.initialize(props)
)(LocationAutocompleteTemplate);
