import { StatsTealiumDataLayerConversionEventInterface } from 'common/module/stats/tealium/data-layer/conversion-event.interface';
import { StatsTealiumDataLayerEventCategoryEnum } from 'common/module/stats/tealium/data-layer/event-category.enum';
import { StatsTealiumProviderInterface } from 'common/module/stats/tealium/provider.interface';

export class HomePageSearchBoxTracker {
  /**
   * Constructor
   */
  constructor(private statsTealium: StatsTealiumProviderInterface) {}

  /**
   * @description send tracking to tealium
   * @param label - tracking eventLabel
   */
  public onSearchButtonClick = (label: string): void => {
    this.statsTealium.send({
      sendToGa: true,
      event_action: 'search',
      event_category: StatsTealiumDataLayerEventCategoryEnum.searchBoxTestVariantB,
      tealium_event: StatsTealiumDataLayerEventCategoryEnum.searchBoxTestVariantB,
      event_label: label,
    });
  };

  /**
   * @description Triggered when clicking on "View commerical properties only" checkbox
   */
  public onToggleCommercialClick = (): void => {
    this.statsTealium.send({
      sendToGa: true,
      event_action: 'click',
      tealium_event: 'secondary_link_clicks',
      event_label: 'view_commercial_properties_only',
      event_category: StatsTealiumDataLayerEventCategoryEnum.homePageClickOutideSearchArea,
    });
  };

  /**
   * @description Triggered when rent-vs-buy link clicked
   */
  public onBuyVsRentLinkClick = (): void => {
    this.statsTealium.send({
      tealium_event: 'flagship',
      event_category: StatsTealiumDataLayerEventCategoryEnum.userInteraction,
      event_value: '1',
      event_action: 'buy_vs_rent_link_click',
      event_label: 'variantA',
    } as StatsTealiumDataLayerConversionEventInterface);
  };
}
