import { domQuerySelector } from 'pf-frontend-common/src/helper/dom/query-selector';
import { StatsContexterService } from 'pf-frontend-common/src/service/stats-contexter/service';

import { LocationModel } from 'common/data/location/model';
import { configGetAbTests } from 'common/helper/config/get-ab-tests';
import { configGetCountry } from 'common/helper/config/get-country';
import { configGetLanguage } from 'common/helper/config/get-language';
import { configGetSettings } from 'common/helper/config/get-settings';
import { PropertySerpLightPopularSearches } from 'common/module/property/serp/light/popular-searches';
import { AbTestService } from 'common/service/ab-test/service';
import { AutocompleteCacheLocationsDelayedService } from 'common/service/autocomplete-cache-locations/delayed/service';
import { FooterService } from 'common/service/footer/service';
import { HeaderService } from 'common/service/header/service';
import { LanguageSelectorService } from 'common/service/language-selector/service';
import { LocationStoreService } from 'common/service/location-store/service';
import { PrivacyPromptService } from 'common/service/privacy-prompt/service';
import { StatsService } from 'common/service/stats/service';
import { UserAuthenticationService } from 'common/service/user-authentication/service';
import { HomePageBundleSettingsInterface } from 'mobile/entry/home-page/bundle/settings.interface';
import { HomePageViewFactory } from 'mobile/module/home-page/view.factory';
import { locationSet1, locationSet2, locationSet3, locationSet4 } from 'mobile/module/property/serp/popular-locations';
import { UserAvatarComponent } from 'mobile/module/user/avatar/component';
import { BottomNavigationService } from 'mobile/service/bottom-navigation/service';

AbTestService().initialize();

const bundleSettings = configGetSettings() as HomePageBundleSettingsInterface;

// Common services
UserAuthenticationService();
FooterService();
HeaderService()
  .setComponents({
    UserAvatarComponent,
  })
  .render();
BottomNavigationService();

LanguageSelectorService();

AutocompleteCacheLocationsDelayedService();

PrivacyPromptService();

// Init location service from popular searches and recommended locations mocks data
const lang = configGetLanguage().current as string;
const popularSearches = PropertySerpLightPopularSearches[configGetCountry().code]?.[lang] || [];
const recommendedLocations =
  [
    ...(locationSet1?.[lang] || []),
    ...(locationSet2?.[lang] || []),
    ...(locationSet3?.[lang] || []),
    ...(locationSet4?.[lang] || []),
  ] || [];

LocationStoreService().initialize({
  models: [
    ...popularSearches.map((location) => {
      const model = new LocationModel();
      model.id = location.id;
      model.name = location.text;
      return model;
    }),
    ...recommendedLocations.map((location) => {
      const model = new LocationModel();
      model.id = String(location.id);
      model.name = location.name;
      return model;
    }),
  ],
});

// Log stats (eventually move to HomePageView)
StatsContexterService().setHomePage(true);
StatsContexterService().setAbTests(configGetAbTests());
const statsService = StatsService();
statsService.pageView();
statsService.homePage();

const element = domQuerySelector(document.body, '[data-qs=home-page]');

const view = HomePageViewFactory();

view.initialize({
  element,
  deepLink: bundleSettings.deepLink,
  search: {
    payload: bundleSettings.search.payload,
    form: bundleSettings.searchForm,
    uri: bundleSettings.search.uri,
  },
});
view.render();
