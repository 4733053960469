import { Fragment, FunctionalComponent, h } from 'preact';

import { HomePageLinksComponent } from '../links/component';
import { HomePageLinksLinkInterface } from '../links/link.interface';

export const HomePageLinkGroupsComponent: FunctionalComponent<{ groups: string }> = ({ groups }) => {
  const groupsData: Array<{ title: string; links: HomePageLinksLinkInterface[] }> = JSON.parse(groups);

  return (
    <Fragment>
      {groupsData.map((group) => (
        <div className='panel wrapper2 footer-link-home__panel'>
          <h2 className='text text--bold text--size3 panel__heading footer-link-home__title-light-search'>
            {group.title}
          </h2>
          <div className='footer-link-home__links-light-search'>
            <HomePageLinksComponent
              links={group.links}
              className='link text text--size2 text--color3 footer-link-home__link-item-light-search'
            />
          </div>
        </div>
      ))}
    </Fragment>
  );
};
