import { FunctionalComponent, h } from 'preact';

export const LoaderTemplate: FunctionalComponent<{
  cssClass?: string;
  isInverted?: boolean;
}> = (props) => (
  <div className={`dropdown__loader ${props.cssClass ? props.cssClass : ''}`}>
    <div className='loader4'>
      <div className={`loader4__dot ${props.isInverted ? 'loader4__dot--inverted' : ''}`} />
      <div className={`loader4__dot ${props.isInverted ? 'loader4__dot--inverted' : ''} loader4__dot--1`} />
      <div className={`loader4__dot ${props.isInverted ? 'loader4__dot--inverted' : ''} loader4__dot--2`} />
    </div>
  </div>
);
