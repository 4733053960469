import { EventEmitterFactory } from 'pf-frontend-common/src/module/event/emitter.factory';

import { PropertyCategoryIdentifierAdapterFactory } from 'common/data/property-category/identifier/adapter.factory';
import { FilterSearchUrlAdapterFactory } from 'common/module/filter/search/url/adapter.factory';
import { FilterService } from 'common/service/filter/service';

import { HomePageSearchBoxTrackerFactory } from './tracker.factory';
import { HomePageSearchBoxViewStore } from './view-store';

export function HomePageSearchBoxViewStoreFactory(): HomePageSearchBoxViewStore {
  return new HomePageSearchBoxViewStore(
    EventEmitterFactory(),
    FilterService(),
    FilterSearchUrlAdapterFactory(),
    PropertyCategoryIdentifierAdapterFactory(),
    HomePageSearchBoxTrackerFactory()
  );
}
