import { useEffect } from 'preact/hooks';

type Handler = (event: MouseEvent) => void;

export function useBottomNavigationOnClickOutside<T extends HTMLElement = HTMLElement>(
  el: T | null,
  handler: Handler,
  mouseEvent: 'mousedown' | 'mouseup' = 'mousedown'
): void {
  useEffect(() => {
    const listener = (event: MouseEvent): void => {
      console.log(el);
      if (!el || el.contains(event.target as Node)) {
        return;
      }

      handler(event);
    };

    document.addEventListener(mouseEvent, listener);

    return (): void => {
      document.removeEventListener(mouseEvent, listener);
    };
  }, [el, handler, mouseEvent]);
}
