import { Link, LinkThemesEnum } from '@propertyfinder/link';
import { FunctionalComponent, h } from 'preact';

import { CountryCodeEnum } from 'common/data/country/code.enum';
import { configGetCountry } from 'common/helper/config/get-country';
import { configGetLanguage } from 'common/helper/config/get-language';
import { ConfigLanguageCodeEnum } from 'common/helper/config/language-code.enum';
import { i18nTranslate } from 'common/helper/i18n/translate';

export const HomePageListYourPropertyWithUsTemplate: FunctionalComponent<{ onClickListWithUs: () => void }> = ({
  onClickListWithUs,
}) => {
  const country = configGetCountry().code;
  const language = configGetLanguage().current;

  const urls: {
    [key in CountryCodeEnum]?: {
      // tslint:disable-next-line: no-shadowed-variable
      [key in ConfigLanguageCodeEnum]?: string;
    };
  } = {
    [CountryCodeEnum.ae]: {
      [ConfigLanguageCodeEnum.en]: 'https://www.propertyfinder.ae/hub/register/',
      [ConfigLanguageCodeEnum.ar]: 'https://www.propertyfinder.ae/hub/register/',
    },
    [CountryCodeEnum.eg]: {
      [ConfigLanguageCodeEnum.en]: 'https://www.propertyfinder.eg/company-registration/en/',
      [ConfigLanguageCodeEnum.ar]: 'https://www.propertyfinder.eg/company-registration/',
    },
  };

  return (
    <div className='menu-secondary__list-your-properties-with-us-container'>
      <Link
        href={urls[country][language]}
        theme={LinkThemesEnum.SECONDARY}
        className='menu-secondary__list-your-properties-with-us-text'
        onClick={onClickListWithUs}
      >
        {i18nTranslate('home-page:list-your-properties-with-us')}
      </Link>
    </div>
  );
};
