import { FunctionalComponent, h } from 'preact';

import { CountryCodeEnum } from 'common/data/country/code.enum';
import { PropertyCategoryIdentifierEnum } from 'common/data/property-category/identifier.enum';
import { abTestsIsVariant } from 'common/helper/ab-tests/is-variant';
import { configGetCountry } from 'common/helper/config/get-country';
import { configGetLanguage } from 'common/helper/config/get-language';
import { i18nTranslate } from 'common/helper/i18n/translate';
import { getFilterDefaultParams } from 'common/module/filter/get-filter-default-params';
import { FilterParamsEnum } from 'common/module/filter/params.enum';
import { FilterSingleSelectionTemplate } from 'common/module/filter-single-selection/template';
import { DataGuruLinkTemplate } from 'common/module/home-page/data-guru-link/template';
import { LibraryButtonComponentTypeEnum } from 'library/button/component-type.enum';
import { ButtonSizeEnum } from 'library/button/size.enum';
import { LibraryButtonTemplate } from 'library/button/template';
import { CheckboxComponent } from 'library/checkbox/component';
import { ChipChoiceComponent } from 'library/chip-choice/component';
import { DropdownVariantEnum } from 'library/dropdown/variant.enum';
import { LocationAutocompleteComponent } from 'mobile/module/location-autocomplete/component';

import { HomePageSearchBoxTemplatePropsInterface } from './template-props.interface';

export const HomePageSearchBoxTemplate: FunctionalComponent<HomePageSearchBoxTemplatePropsInterface> = ({
  errors,
  filterParams,
  propertyCategoryIdentifierAdapter,
  onChangeFilters,
  onSubmit,
  onToggleCommercialProperties,
  onBuyVsRentClick,
}) => {
  const category = propertyCategoryIdentifierAdapter.getData(
    parseInt(filterParams[FilterParamsEnum.categoryId].value, 10)
  );

  const isAE = configGetCountry().code === CountryCodeEnum.ae;
  const isCommercial = propertyCategoryIdentifierAdapter.isCommercial(category as PropertyCategoryIdentifierEnum);

  const toggleCommercial = (): void => {
    let newCategory: PropertyCategoryIdentifierEnum;
    if (isCommercial) {
      newCategory =
        category === PropertyCategoryIdentifierEnum.commercialForRent
          ? PropertyCategoryIdentifierEnum.residentialForRent
          : PropertyCategoryIdentifierEnum.residentialForSale;
    } else {
      newCategory =
        category === PropertyCategoryIdentifierEnum.residentialForRent
          ? PropertyCategoryIdentifierEnum.commercialForRent
          : PropertyCategoryIdentifierEnum.commercialForSale;
    }

    onToggleCommercialProperties();

    onChangeFilters({
      [FilterParamsEnum.categoryId]: {
        ...filterParams[FilterParamsEnum.categoryId],
        value: propertyCategoryIdentifierAdapter.getValue(newCategory),
      },
      [FilterParamsEnum.bedrooms]: {
        ...filterParams[FilterParamsEnum.bedrooms],
        value: [],
      },
      [FilterParamsEnum.maxArea]: {
        ...filterParams[FilterParamsEnum.maxArea],
        value: '',
      },
    });
  };

  const { [FilterParamsEnum.locationsIds]: locationError } = errors;

  const { choices: propertyTypeIdChoices, value: propertyTypeId } = filterParams[FilterParamsEnum.propertyTypeId];

  let bedroomChoices: any[];
  let bedroom: string;

  if (!isCommercial) {
    bedroomChoices = filterParams[FilterParamsEnum.bedrooms].choices;
    bedroom = filterParams[FilterParamsEnum.bedrooms].value[0] || '';
  }

  const { choices: maxAreaChoices, value: maxArea } = filterParams[FilterParamsEnum.maxArea];

  const { choices: maxPriceChoices, value: maxPrice } = filterParams[FilterParamsEnum.maxPrice];
  const pricePlaceholder = i18nTranslate(`Max Price ({currency})`).replace('{currency}', i18nTranslate('AED'));

  return (
    <form onSubmit={onSubmit}>
      <div className='search-box'>
        <div className='search-box__category-area search-box__full-row'>
          <ChipChoiceComponent
            containerClassName='search-box__category'
            options={[
              {
                text: i18nTranslate('Rent'),
                value: isCommercial
                  ? PropertyCategoryIdentifierEnum.commercialForRent
                  : PropertyCategoryIdentifierEnum.residentialForRent,
              },
              {
                text: i18nTranslate('Buy'),
                value: isCommercial
                  ? PropertyCategoryIdentifierEnum.commercialForSale
                  : PropertyCategoryIdentifierEnum.residentialForSale,
              },
            ]}
            name='category'
            selected={category}
            onCheck={(e, selectedOption) => {
              onChangeFilters({
                [FilterParamsEnum.categoryId]: {
                  ...filterParams[FilterParamsEnum.categoryId],
                  value: propertyCategoryIdentifierAdapter.getValue(
                    selectedOption.value as PropertyCategoryIdentifierEnum
                  ),
                },
              });
            }}
          />
          {abTestsIsVariant('test116', 'variantA') && (
            <div className='search-box__category-extra'>
              <a
                href={`/${configGetLanguage().isRtl ? 'ar' : 'en'}/rent-vs-buy-calculator`}
                className='buy-vs-rent-link'
                onClick={onBuyVsRentClick}
              >
                {i18nTranslate('BuyVsRent_NotSure')}
              </a>
            </div>
          )}
        </div>

        <div className='search-box__full-row'>
          <LocationAutocompleteComponent
            className='search-box__autocomplete'
            onChange={(locationIds) => {
              onChangeFilters({
                [FilterParamsEnum.locationsIds]: {
                  value: locationIds,
                },
              });
            }}
            placeholderText={i18nTranslate('City, community or building')}
            error={locationError}
            renderNoSuggestions={i18nTranslate('multi-location-selector-no-suggestions-new')}
          />
          {isAE && (
            <div className='search-box__data-guru-link'>
              <DataGuruLinkTemplate category={category} />
            </div>
          )}
        </div>

        <FilterSingleSelectionTemplate
          className='search-box__full-row'
          label={i18nTranslate('Property type')}
          choices={[
            {
              value: getFilterDefaultParams()[FilterParamsEnum.propertyTypeId],
              label: i18nTranslate('Property type'),
            },
            ...propertyTypeIdChoices,
          ]}
          value={propertyTypeId}
          onChange={({ value }) => {
            onChangeFilters({
              [FilterParamsEnum.propertyTypeId]: { value, choices: propertyTypeIdChoices },
            });
          }}
        />
        {isCommercial ? (
          <FilterSingleSelectionTemplate
            label={i18nTranslate('Max. area')}
            choices={[
              {
                value: getFilterDefaultParams()[FilterParamsEnum.maxArea],
                label: i18nTranslate('Max. area'),
              },
              ...maxAreaChoices,
            ]}
            value={maxArea}
            onChange={({ value }) => {
              onChangeFilters({
                [FilterParamsEnum.maxArea]: { value, choices: maxAreaChoices },
              });
            }}
          />
        ) : (
          <FilterSingleSelectionTemplate
            label={i18nTranslate('Bedrooms')}
            choices={[
              {
                value: '',
                label: i18nTranslate('Bedrooms'),
              },
              ...bedroomChoices,
            ]}
            value={bedroom}
            variant={bedroom === '' ? DropdownVariantEnum.secondary : DropdownVariantEnum.primary}
            onChange={({ value }) => {
              onChangeFilters({
                [FilterParamsEnum.bedrooms]: { value: [value], choices: bedroomChoices },
              });
            }}
          />
        )}
        <FilterSingleSelectionTemplate
          label={pricePlaceholder}
          choices={[
            {
              value: getFilterDefaultParams()[FilterParamsEnum.maxPrice],
              label: pricePlaceholder,
            },
            ...maxPriceChoices,
          ]}
          value={maxPrice}
          onChange={({ value }) => {
            onChangeFilters({
              [FilterParamsEnum.maxPrice]: { value, choices: maxPriceChoices },
            });
          }}
        />

        <CheckboxComponent
          id='commercial-checkbox'
          checked={isCommercial}
          containerClassName='search-box__full-row'
          labelClassName='search-box__commercial-checkbox-label'
          onChange={toggleCommercial}
        >
          {i18nTranslate('View commercial properties only')}
        </CheckboxComponent>
        <LibraryButtonTemplate
          cssClass='search-box__submit search-box__full-row'
          componentType={LibraryButtonComponentTypeEnum.primary}
          type='submit'
          size={ButtonSizeEnum.regular}
        >
          {i18nTranslate('Search')}
        </LibraryButtonTemplate>
      </div>
    </form>
  );
};
